import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, Col, Row } from "reactstrap"

const Cards = props => {
  const notifications = useSelector(state => state.Notifications)

  const [data, setData] = useState([
    {
      title: "Total Of Country",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Pending Resources",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Websites",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Views",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Favourites",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Layouti Websites",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Active Websites",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Categories",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Sub-Categories",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Downloads",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Tages",
      total: 0,
      icon: "",
    },
    {
      title: "# of click on logo",
      total: 0,
      icon: "",
    },
    {
      title: "# of click on social",
      total: 0,
      icon: "",
    },
    {
      title: "# of click on Footer",
      total: 0,
      icon: "",
    },
    {
      title: "# of click on drawer",
      total: 0,
      icon: "",
    },
  ])

  const [userData, setUserData] = useState([
    {
      title: "Total Of Users",
      total: 0,
      icon: "",
    },
    {
      title: "Total Of Visitors",
      total: 0,
      icon: "",
    },
  ])

  useEffect(() => {
    if (props.data) {
      setData([
        {
          title: "Total Of Country",
          total: props.data.totCountries,
          icon: "",
        },
        {
          title: "Total Of Pending Resources",
          total: props.data.totPendingRequest,
          icon: "",
        },
        {
          title: "Total Of Websites",
          total: props.data.totPages,
          icon: "",
        },
        {
          title: "Total Of Views",
          total: props.data.totViewers,
          icon: "",
        },
        {
          title: "Total Of Favourites",
          total: props.data.totLikes,
          icon: "",
        },
        {
          title: "Total Of Layouti Websites",
          total: props.data.totLayoutiPages,
          icon: "",
        },
        {
          title: "Total Of Active Websites",
          total: props.data.totActivePages,
          icon: "",
        },
        {
          title: "Total Of Categories",
          total: props.data.totCategories,
          icon: "",
        },
        {
          title: "Total Of Sub-Categories",
          total: props.data.totSubCategories,
          icon: "",
        },
        {
          title: "Total Of Downloads",
          total: props.data.totDownloads,
          icon: "",
        },
        {
          title: "Total Of Tages",
          total: props.data.totTages,
          icon: "",
        },
        {
          title: "# of click on logo",
          total: props.data.totLogo,
          icon: "",
        },
        {
          title: "# of click on social",
          total: props.data.totSocial,
          icon: "",
        },
        {
          title: "# of click on Footer",
          total: props.data.totFooter,
          icon: "",
        },
        {
          title: "# of click on drawer",
          total: props.data.totDrawer,
          icon: "",
        },
      ])

      setUserData([
        {
          title: "Total Of Users",
          total: props.data.totUsers || "-",
          icon: "",
        },
        {
          title: "Total Of Visitors",
          total: props.data.totVisitors,
          icon: "",
        },
      ])
    }
  }, [props.data])

  return (
    <Row>
      <h2 className="fs-3 fw-bolder text-black mb-3">User Data</h2>

      {userData.map((card, i) => (
        <Col xl={4} md={4} sm={6} xs={12} key={i}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="mb-2 fs-5 fw-bolder text-black">{card.title}</p>
                  <h5 className="mb-0 fs-3 fw-bolder">{card.total}</h5>
                </div>
                <div className="avatar-sm ms-auto"></div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
      <Col xl={4} md={4} sm={6} xs={12}>
        <Card className="blog-stats-wid">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-3">
                <p className="mb-2 fs-5 fw-bolder text-black">
                  {"Total Of Online Users"}
                </p>
                <h5 className="mb-0 fs-3 fw-bolder">
                  {notifications?.totOnline}
                </h5>
              </div>
              <div className="avatar-sm ms-auto"></div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <h2 className="fs-3 mt-4 fw-bolder text-black mb-3">General Data</h2>
      {data.map((card, i) => (
        <Col xl={3} md={4} sm={6} xs={12} key={i}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="mb-2 fs-5 fw-bolder text-black">{card.title}</p>
                  <h5 className="mb-0 fs-3 fw-bolder">{card.total}</h5>
                </div>
                <div className="avatar-sm ms-auto"></div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default Cards
