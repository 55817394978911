import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import './configration.scss'
import axios, { Axios } from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
import SeoComponent from "../Components/SeoComponent";
const ChannelSeoPage = () => {
    const dispatch = useDispatch()
    const history  = useHistory()
    const   user = useSelector(state => state.UserData.user)
    const [pending, setPending] = useState([])
    const [data, setData] = useState(null)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };




    useEffect(() => {   
        dispatch(setTitle("SEO"))
        axios.get(`${process.env.REACT_APP_API}/dashboard/channels/seo`, {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          }).then(res => {
            setData(res.data.data)
          })
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>SEO | Resources</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row>
                <Col md="3">
                    <Sticky topOffset={-300} >
                        <Nav pills className="flex-column justify-content-between navs_aside">
                            <NavItem  className="nav___item border_bottom">
                                <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    "": true,
                                    active: verticalActiveTab === "1",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("1");
                                }}
                                >
                                Home page

                                </NavLink>
                            </NavItem>

                            <NavItem className="nav___item border_bottom">
                                <NavLink
                                style={{ cursor: "pointer", borderRadius: 0}}
                                className={classnames({
                                    "": true,
                                    active: verticalActiveTab === "2",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("2");
                                }}
                                >
                                Main page

                                </NavLink>
                            </NavItem>

                            <NavItem className="nav___item border_bottom">
                                <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    "": true,
                                    active: verticalActiveTab === "3",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("3");
                                }}
                                >
                                Details Page 
                                </NavLink>
                            </NavItem>
                           
                        </Nav>
                    </Sticky>
                </Col>

                <Col md="9">
                    <Card>
                        <CardBody>
                            <TabContent
                            activeTab={verticalActiveTab}
                            className="text-muted table__content mt-4 mt-md-0"
                            >
                                <TabPane tabId="1">
                                    <SeoComponent page="homePage" data={data?.HomePage}/>
                                </TabPane>
                                <TabPane tabId="2" >
                                    <SeoComponent page="mainPage"data={data?.MainPage}/>
                                </TabPane>
                                <TabPane tabId="3">
                                    <SeoComponent page="detailsPage" data={data?.DetailsPage}/>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Col>

        </Container>
        </div>
    </React.Fragment>
)
}

export default ChannelSeoPage



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}