import React, { useState, useEffect } from "react"
import { Row, Col, Form, Label, Input } from "reactstrap"
import ReactLoading from "react-loading"
import axios from "axios"
import { toast } from "react-toastify"

const FooterContent = () => {
  const [active, setActive] = useState(false)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")

  function submit(e) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }

    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/resources/footerContent/save`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setActive(false)
        toast.success("Success Updated")
      })
      .catch(err => {
        setActive(false)
        toast.error("Something went wrong")
      })
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/resources/footerContent`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        let data = res.data.data
        setTitleEn(data?.titleEn)
        setTitleAr(data?.titleAr)
        setDescEn(data?.descriptionEn)
        setDescAr(data?.descriptionAr)
      })
      .catch(err => {
      })
  }, [])

  return (
    <div>
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Main Title (english)</Label>
              <Input
                id="itemnameen"
                name="titleEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Main Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="titleAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                name="describtionEn"
                className="form-control  mb-0"
                id="specification"
                rows="7"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                name="describtionAr"
                className="form-control  mb-0"
                id="specification"
                rows="7"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
      </Form>
      {/* <hr style={{ margin: "20px -20px 20px -20px" }} /> */}
      <Row className="mt-2">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button onClick={submit}>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FooterContent
