/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { useHistory } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useSelector, useDispatch } from "react-redux"
import { setTitle, getProjects } from "../../store/actions"
import { toast } from "react-toastify"
import TreePicker from "rsuite/TreePicker"

import "rsuite/dist/rsuite.min.css"
import prettyBytes from "pretty-bytes"
import { getDateMeta } from "@fullcalendar/core"
import AdminPagination from "pages/Utilities/AdminPagination"

const InnerPageResourceTable = props => {
  const queryParameters = new URLSearchParams(window.location.search)
  const pageNum = queryParameters.get("page")
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(state => state?.UserData?.user)
  const [productData, setProductData] = useState([])
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const [disabledNum, setDisabledNum] = useState([])
  const [categoryID, setCategoryID] = useState("")
  const [category, setCategory] = useState("")
  const [categoriess, setCateogriess] = useState([])
  const categoryRef = useRef(null)
  const sizePerPage = 10;
  const [page, sePage] = useState(null)
  const [totalSize, setTotalSize] = useState("130")
  const [filter, setFilter] = useState(null)

  
  useEffect(() => {
    sePage(pageNum?pageNum:1)
  }, [pageNum])

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
    },
    {
      dataField: "titleEn",
      text: "Title (English)",
      sort: true,
    },
    {
      dataField: "titleAr",
      text: "Title (Arabic)",
      sort: true,
    },
    {
      dataField: "categories",
      text: "Category",
      sort: true,
    },
    {
      dataField: "tagData",
      text: "Tag",
      sort: true,
    },
    {
      dataField: "country",
      text: "Counrty (English)",
      sort: true,
    },
    {
      dataField: "color",
      text: "Color",
      sort: true,
    },
    {
      dataField: "clicks",
      text: "Website Link",
      sort: true,
    },
    {
      dataField: "likes",
      text: "Likes",
      sort: true,
    },

    {
      dataField: "downloads",
      text: "Design Link",
      sort: true,
    },

    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "createdOn",
      text: "Created On",
      sort: true,
    },
  ]

 


  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function clickHandle(id) {
    if (id) {
      return history.push(`/resources/detailsPage/Edit?id=${id}&page=${page}`)
    }
    history.push(`/resources/detailsPage/Add?page=${page}`)
  }

  function deleteAvatar(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(products => products.filter(prod => prod.id != id))
        setMsg("Active Resource Deleted Successfuly !")
        setsuccess_msg(true)
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  let getCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage/categories?type=2`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setCateogriess(res.data.data)
        setDisabledItems(res.data.data)
      })
      .catch(err => {})
  }

  let recursionCheckCategoryHasChildren = category => {
    if (category.children.length == 0) {
      return null
    } else {
      setDisabledNum(prevVal => [...prevVal, category.nameEn])
      category.children.forEach(childCategory => {
        recursionCheckCategoryHasChildren(childCategory)
      })
    }
  }

  let setDisabledItems = categories => {
    categories.forEach(category => {
      recursionCheckCategoryHasChildren(category)
    })
  }

  useEffect(getCategories, [])

  let getFilterData = value => {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage?perPage=${sizePerPage}&page=${page}&category=${value.id}&status=${value.status}&type=2`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setCategoryID(value.nameEnCount)
        setTotalSize(res.data.data.pagination.total)
        setProductData(
          res.data.data.pages.map(page => {
            return {
              ...page,
              titleEn: page.titleEn?page.titleEn:<span className="fs-4">-</span>,
              titleAr: page.titleAr?page.titleAr:<span className="fs-4">-</span>,
              image: (
                <img
                  src={page.image}
                  onClick={e => setOpen(e.target.src)}
                  className="usernametabl"
                  alt=""
                />
              ),
              categories:
                page?.categories.length !== 0 ? (
                  page?.categories.map((c, i) => (
                    <span
                      key={i}
                      className={`${c.nameEn === 1 ? "" : "image-opacity"}`}
                      style={{
                        backgroundColor: "rgb(216 216 216)",
                        color: "rgb(0, 0, 0)",
                        borderRadius: "45px",
                        padding: "5px 20px",
                        margin: "0px 2px",
                        display: "inline-block",
                      }}
                    >
                      {c.nameEn}
                    </span>
                  ))
                ) : (
                  <span className="fs-4">-</span>
                ),

              color: page?.color ? (
                <span
                  className={`me-2`}
                  style={{
                    backgroundColor: page?.color,
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    border: "1px solid #bdbdbd",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span className="fs-4">-</span>
              ),

              titleEn: page.titleEn,
              titleAr: page.titleAr,
              clicks: page.Clicks,
              country: page.country.nameEn,
              downloads: page.Downloads,
              tagData: (
                <span
                  style={{
                    backgroundColor: page?.tag?.color,
                    color: "rgb(0, 0, 0)",
                    borderRadius: "45px",
                    padding: "5px 20px",
                    margin: "0px 2px",
                    display: "inline-block",
                  }}
                >
                  {page?.tag?.titleEn}
                </span>
              ),
              actions: (
                <div className="d-flex">
                  <a style={editBtn} href={page.link}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    View
                  </a>
                  <button style={editBtn} onClick={() => clickHandle(page.id)}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>
                  {user?.roleAccess[3]?.Delete&&<button
                    style={deleteBtn}
                    onClick={e => deleteAvatar(page.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>}
                </div>
              ),
              createdOn: page.created_at ? (
                page.created_at
              ) : (
                <span className="fs-4">-</span>
              ),
            }
          })
        )
      })
      .catch(err => {
        toast.error("Something went wrong", {
          theme: "light",
          position: "top-right",
        })
      })
  }

  useEffect(() => {
    dispatch(setTitle("Active Resources"))
    page&&axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage?perPage=${sizePerPage}&page=${page}&search=${filter}&type=2`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setTotalSize(res.data.data.pagination.total)
        setProductData(
          res.data.data.pages.map(page => {
            return {
              ...page,
              image: (
                <img
                  src={page.image}
                  onClick={e => setOpen(e.target.src)}
                  className="usernametabl"
                  alt=""
                />
              ),
              categories:
                page?.categories.length !== 0 ? (
                  page?.categories.map((c, i) => (
                    <span
                      key={i}
                      className={`${c.nameEn === 1 ? "" : "image-opacity"}`}
                      style={{
                        backgroundColor: "rgb(216 216 216)",
                        color: "rgb(0, 0, 0)",
                        borderRadius: "45px",
                        padding: "5px 20px",
                        margin: "0px 2px",
                        display: "inline-block",
                      }}
                    >
                      {c.nameEn}
                    </span>
                  ))
                ) : (
                  <span className="fs-4">-</span>
                ),

                titleEn: page.titleEn?page.titleEn:<span className="fs-4">-</span>,
                country: page.country.nameEn,
                titleAr: page.titleAr?page.titleAr:<span className="fs-4">-</span>,
              clicks: page.Clicks,
              downloads: page.Downloads,
              color: page?.color ? (
                <span
                  className={`me-2`}
                  style={{
                    backgroundColor: page?.color,
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    border: "1px solid #bdbdbd",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span className="fs-4">-</span>
              ),
              tagData: page.tag?(
                <span
                  style={{
                    backgroundColor: page?.tag?.color,
                    color: "rgb(0, 0, 0)",
                    borderRadius: "45px",
                    padding: "5px 20px",
                    margin: "0px 2px",
                    display: "inline-block",
                  }}
                >
                  {page?.tag?.titleEn}
                </span>
              ):<span className="fs-4">-</span>,
              actions: (
                <div className="d-flex">
                  <a
                    style={{ ...editBtn, marginRight: "10px" }}
                    href={page.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="bx bx-link fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    View
                  </a>
                  <button style={editBtn} onClick={() => clickHandle(page.id)}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>

                  {user?.roleAccess[3]?.Delete&&<button
                    style={deleteBtn}
                    onClick={e => deleteAvatar(page.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>}
                </div>
              ),
              createdOn: page.created_at ? (
                page.created_at
              ) : (
                <span className="fs-4">-</span>
              ),
            }
          })
        )
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }, [page, filter])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Resource | Pages Details</title>
        </MetaTags>
        <div className="container-fluid p-0">
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            ></SweetAlert>
          ) : null}
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
           <Card>
              <CardBody>
              <Row>
            <Col sm="12">
              <div ref={categoryRef}>
                <TreePicker
                  data={categoriess}
                  labelKey="nameEnCount"
                  valueKey="nameEnCount"
                  value={categoryID}
                  disabledItemValues={[1, ...disabledNum]}
                  onSelect={(value, category, e) => {
                    setCategory(value)
                    setCategoryID(value?.nameEn)
                    getFilterData(value)
                  }}
                  className={""}
                  onClick={e => e.target.classList.remove("is-invalid")}
                  placeholder="Select Category"
                  renderValue={(value, category, selectedElement) => {
                    if (value.selected === false) {
                      setCategory(value)
                      setDisabledNum(disabledNum => [...disabledNum, value.id])
                    }
                    return (
                      <span>
                        <i
                          className="rs-icon rs-icon-map-marker"
                          value={category.nameEn}
                        />{" "}
                        {category.nameEn}
                      </span>
                    )
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
              </CardBody>
            </Card>
          <Card>
            <CardBody>
            
          <Row>
            <Col>
                  {page&&<AdminPagination 
                    data={productData} 
                    page={page}
                    setFilter={setFilter}
                    keyFiled='id'
                    sizePerPage={sizePerPage} 
                    totalSize={totalSize}
                    url=''
                    none={true}
                    onTableChange={(type, { page }) => {
                      sePage(page)
                    }} 
                    columns={columns}
                  />}
            </Col>
          </Row>
            </CardBody>
          </Card>
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default InnerPageResourceTable

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
