/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect, Fragment } from "react"
import { Button, Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link, useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import "../../Components/specification.scss"




const AddDesignerFormResources = props => {
  let p = props
  const [name_en, setNameEn] = useState("")
  const [nameEnError, setNameEnError] = useState(null)
  const [descriptionEnError, setDescriptionEnError] = useState(null)
  const [nameArError, setNameArError] = useState(null)
  const [descriptionArError, setDescriptionArError] = useState(null)
  const [imageError, setImageError] = useState(null)
  const [image, setImage] = useState(null)
  const [name_ar, setNameAr] = useState("")
  const [descriptionAr, setDescriptionAr] = useState("")
  const [descriptionEn, setDescriptionEn] = useState("")
  const [expertise, setExpertise] = useState("")
  const [expertiseError, setExpertiseError] = useState(null)
  const [follwers, setFollwers] = useState("")
  const [follwersError, setFollwersError] = useState(null)

  const [isOpen, setisOpen] = useState(false)
  const [rows2, setrows2] = useState([{ id: 1 }])


  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const imageRef = useRef(null);

  const [multiData, setMultiData] = useState([{
    id:1, 
    link:"",
    status:'new'
  }])
  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setMultiData(data => data.concat({
    id:modifiedRows.length, 
    link:"",
    status:'new'
  }))
  }

  function deleteCard(key,cardId,status){    
    handleRemoveRow(key,cardId)
  }
  function handleRemoveRow(id,cardId) {
    if (id !== 0) {
      setrows2(r => r.filter((x,i) => i !== id))
      setMultiData(d => d.filter((x,i) => i !== id))
    }
  }
  function multiChange(e, key, type,event) {
    setMultiData(points =>
      points.map((data, index) => {
        if (index === key && type === "link") {
          return { ...data, link: e.target.value }
        } 
        return data
      })
    )
    
  }





  function handleAcceptedFiles(files) {
    files.map(file =>
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: prettyBytes(file.size)
        })
    )
    setImage(files[0])
  }




  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      descriptionAr: descriptionAr,
      descriptionEn: descriptionEn,
      follwers:follwers,
      expertise:expertise,
      id,
    }

    for (let key in data) {
      formData.append(key, data[key])
    }

    multiData.forEach((item,index)=>{
      formData.append(`Links[${index}][link]`, item.link)
    })
    
    formData.append('image', image);
    
    let method = props.designer? `/edit`: ``
    
    axios({
      method: 'POST',
      url:`${process.env.REACT_APP_API}/dashboard/socialPost/designer/add`,
      data: formData,
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    }).then(res => {
        props.getDesignerData()
        toast.success(`Designer  ${props.designer?'Updated':"Added"} Success`, {position: "top-right"})
        setLoading(false)
        props.setActive(false)
        props.setDesigner(null)
      })
      .catch((error)=>{
          setLoading(false)
          if(error.response.data.error != null){
            if(error.response.data.error.descriptionEn != null){
              error.response.data.error.descriptionEn.map(error =>{
                setDescriptionEn(error)
              })
            }
            if(error.response.data.error.nameEn != null){
              error.response.data.error.nameEn.map(error =>{
                setNameEnError(error)
              })
            }
            if(error.response.data.error.expertise != null){
              error.response.data.error.expertise.map(error =>{
                setExpertiseError(error)
              })
            }
            if(error.response.data.error.follwers != null){
              error.response.data.error.follwers.map(error =>{
                setFollwersError(error)
              })
            }
          }
      })
    
  }

  useEffect(() => {
    if (p.designer) {
      setNameEn(p.designer.nameEn)
      setNameAr(p.designer.nameAr)
      setDescriptionAr(p.designer.descriptionAr)
      setDescriptionEn(p.designer.descriptionEn)
      setId(p.designer.id)
      setImage(p.designer.image)
      setMultiData(p.designer.Links)
      setFollwers(p.designer.follwers)
      setExpertise(p.designer.expertise)
      return
    }
    
  }, [p.designer])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      {isOpen ? (
            <Lightbox
                mainSrc={image.preview ? image.preview : image}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "650px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            Designer
          </div>
          <button onClick={() => (props.setActive(false), props.setDesigner(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          <Row className="">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Name (English)</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                  style={{ border: nameEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  nameEnError ? 
                  <span className="fs-6 text-danger">
                    { nameEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="">
                <Label htmlFor="productdesc">Name (Arabic)</Label>
                <Input
                  name="nameAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm="12">
              <div>
                <div className="d-flex">
                  <Label htmlFor="productdesc">Description (English)</Label>
                  
                </div>
                <textarea
                  name="descriptionEn"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={descriptionEn}
                  onChange={e => setDescriptionEn(e.target.value)}
                  maxLength="125"
                  style={{ border: descriptionEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}

                />
                 {
                  descriptionEnError ? 
                  <span className="fs-6 text-danger">
                  { descriptionEnError }
                  </span> : ''
                }
              </div>
            </Col>
            <Col sm="12" className="mt-3">
              <div>
                <div className="d-flex">
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                 
                </div>
                <textarea
                  name="descriptionAr"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={descriptionAr}
                  onChange={e => setDescriptionAr(e.target.value)}
                  dir="rtl"
                  maxLength="125"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Followers</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={follwers}
                  onChange={e => setFollwers(e.target.value)}
                  style={{ border: follwersError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  follwersError ? 
                  <span className="fs-6 text-danger">
                    { follwersError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Expertise</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={expertise}
                  onChange={e => setExpertise(e.target.value)}
                  style={{ border: expertiseError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  expertiseError ? 
                  <span className="fs-6 text-danger">
                    { expertiseError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>
          <Row>
                        <Col>
                            <div className="mb-3 mt-3">
                                <Label htmlFor="metatitle">Designer Image</Label>
                                <div className="mb-3" ref={imageRef}>
                                    <Input 
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleAcceptedFiles(Object.values(e.target.files), e.target.value = '')}
                                        style={{borderRadius: 0}}
                                    />
                                   {
                                    imageError ? 
                                    <span className="fs-6 text-danger">
                                      { imageError }
                                    </span> : ''
                                  }
                                </div>
                            </div>
                        </Col>
                        {image? <Row className="align-items-center" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                            <Col className="col-auto">
                                <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={image.name ? image.name : ''}
                                src={image.name ? image.preview : image}
                                style={{objectFit: "cover"}}
                                />
                            </Col>
                            <Col>
                                <Link
                                to="#"
                                className="text-muted "
                                >
                                {image.name}
                                </Link>
                                <p className="mb-0">
                                <strong>{image.formattedSize}</strong>
                                </p>
                            </Col>
                            <Col className='p-0'>
                                <div className="image__options">
                                    <button className="p-0"  onClick={(e) => setisOpen(true)}><i className="far fa-eye  fs-5 p-0"></i></button>
                                    <button className='p-0' style={{color: "#ff3838"}} onClick={(e) => setImage(null)}><i className='bx bx-trash fs-4'></i></button>
                                </div>
                            </Col>
                        </Row>:null}
                    </Row>
                    {(multiData || []).map((formRow, key) => (
              <Fragment key={key}>
                <Row className="mt-4">
                  
                  <Col sm="12">
                    <div>
                      <Label htmlFor="productdesc">Link </Label>
                      <Row>
                        <Col sm="11">
                          <Input
                            id="itemnameen"
                            name="itemnameen"
                            type="text"
                            className="form-control input_cus"
                            placeholder="e.g. Focus Keypharse"
                            value={formRow?.link}
                            onChange={e => multiChange(e, key, "link")}
                          />
                        </Col>
                        <Col sm="1">
                            {key !== 0 ? (
                            <Button
                              color="none"
                              variant="primary"
                              className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                              id="unknown-btn"
                              style={{
                                color: "#ff3838",
                                boxShadow: "none",
                                height: "45px",
                                borderRadius: 0,
                              }}
                              onClick={e => {
                                deleteCard(key,formRow.id,formRow?.status=='new'?'new':'old')
                              }}
                            >
                              <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> 
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                      
                    </div>
                  </Col>
                     
                </Row>
              

                
                
               
              </Fragment>
              ))}
              {/* <hr style={{ margin: "25px -20px 20px -20px" }} /> */}
              <Button
                onClick={() => {
                  handleAddRowNested()
                }}
                color="none"
                className="mt-4 fs-5 fw-bold p-0 text-left"
                
              >
                <i className="bx bx-plus-circle fs-4" /> <span>Add Link</span>
              </Button>
              <hr style={{ margin: "20px -20px 0px -20px" }} />
                
                
          
          
          
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={() => (props.setActive(false), props.setDesigner(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}

export default AddDesignerFormResources

const modulesQuill = {
  toolbar: {
    container: [
      [{ "header": [1, 2, 3, 4, 5, 6, false] }],
      [{ "font": [] }],
      [{ "align": [] }],
      ["bold", "italic", "underline"],
      [{ "list": "ordered" }, { "list": "bullet" }, { "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "custom-color"] }, { "background": [] }, "link", "emoji"]
    ],
    handlers: {
      "color": function(value) {
        if (value === "custom-color") value = window.prompt("Enter Hex Color Code")
        this.quill.format("color", value)
      }
    }
  },
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      },
      handleEnter: {
        key: 13,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      }
    }
  }
}

const formatsQuill = [
  "header", "font", "size",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent", "align",
  "link", "image", "background", "color", "emoji"
]