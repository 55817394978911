import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import { getToken, Fire } from "./FirebaseConfig"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import axios from "axios"
import { getMainData, getNotifications } from "store/actions"
import { MetaTags } from "react-meta-tags"

const App = props => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const mainData = useSelector(state => state.MainData.data)
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  useEffect(() => {
    if (user) {
      getToken().then(firebase_token => {
        axios.post(
          `https://api.resourchub.com/api/dashboard/notifications/updateFirebaseToken`,
          {
            firebase_token,
          },
          {
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        )
      })

      axios
        .get(
          `https://api.resourchub.com/api/dashboard/notifications/latest`,

          {
            headers: {
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        )
        .then(res => {
          dispatch(
            getNotifications({
              notifications: res.data.data.notifications,
              notifyCount: res.data.data.notificationsCount,
              pendingCount: res.data.data.pendingNotificationsCount,
              userCount: res.data.data.userNotificationsCount,
              totOnline: res.data.data.totOnline,
            })
          )
        })
    }
  }, [user])

  const Layout = getLayout()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/dashboardSetting`).then(res => {
      dispatch(getMainData(res.data.data))
      document.documentElement.style.setProperty(
        "--main-color",
        res.data.data.buttonColor
      )
      document.documentElement.style.setProperty(
        "--side-color",
        res.data.data.sideBarColor
      )
      document.documentElement.style.setProperty(
        "--text-color",
        res.data.data.textSideBarColor
      )
    })
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <link rel="icon" href={mainData?.favIcon} />
      </MetaTags>
      <Fire />
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
