/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { useHistory } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useSelector, useDispatch } from "react-redux"
import { setTitle, getProjects } from "../../../store/actions"
import { toast } from "react-toastify"
import TreePicker from 'rsuite/TreePicker';
import 'rsuite/dist/rsuite.min.css';
import prettyBytes from "pretty-bytes";
import { getDateMeta } from "@fullcalendar/core"
import AdminPagination from "pages/Utilities/AdminPagination"
import NewsForm from "./Forms/NewsForm"


const NewsTable = props => {
  const queryParameters = new URLSearchParams(window.location.search)
  const pageNum = queryParameters.get("page")
  const history = useHistory()
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const user = useSelector(state => state.UserData?.user)
  const sizePerPage = 10;
  const [page, setPage] = useState(1)
  const [totalSize, setTotalSize] = useState("10")
  const [filter, setFilter] = useState(null)
  const [news, setNews] = useState(null)

  
  useEffect(() => {
    setPage(pageNum?pageNum:1)
  }, [pageNum])
  
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    
    {
      dataField: "category",
      text: "Category ",
      sort: true,
    },
    {
      dataField: "titleEn",
      text: "Title ",
      sort: true,
    },
    
    {
      dataField: "authorEn",
      text: "Author ",
      sort: true,
    },
   
    
   
    
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Created Date",
      sort: true,
    },
    {
      dataField: "createdDateDiff",
      text: "Created Date Diff",
      sort: true,
    },
  ]
  const selectRow = {
    mode: 'checkbox'
  }

  const onPageHandler =  (type, { page }) => {
    setPage(page)
  }
  

  const pageOptions = {
    totalSize: totalSize,
    custom: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "75",
        value: 75,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: totalSize,
      },
    ],
  }

  

  const { SearchBar } = Search

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }


  

  function deleteNews(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/blogs/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        GetData(page)
        toast.success("News Deleted Successfuly !")
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  


  let getFilterData = (value)=>{
    setCategory(value.id)
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/blogs?perPage=${sizePerPage}&page=${page}&category=${value.id}&search=${filter}`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        GetData()
        setTotalSize(res.data.data.pagination.total)
        
        
        setCategoryID(value.nameEnCount)
        setProductData(
          res.data.data.pages.map(page => {
            return {
              ...page,
              image: (
                <img
                  src={page.image}
                  onClick={e => setOpen(e.target.src)}
                  className="usernametabl"
                  alt=""
                />
              ),
              categories: page?.categories.length !== 0? page?.categories.map((c,i)=> <span key={i} className={`${c.nameEn===1?'':'image-opacity'}`}  style={{backgroundColor: 'rgb(216 216 216)',
                  color: 'rgb(0, 0, 0)',
                  borderRadius: '45px',
                  padding: '5px 20px',
                  margin: '0px 2px',
                  display: 'inline-block'}}>{c.nameEn}</span>
                  ):<span className="fs-4">-</span>,

                  color: page?.color ? (<span className={`me-2`} style={{backgroundColor: page?.color, width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  border: '1px solid #bdbdbd',
                  display: 'inline-block'}}></span>) 
                  : (<span className="fs-4">-</span>),
              
              
              titleEn: page.titleEn,
              country: page.country.nameEn,
              titleAr: page.titleAr,
              clicks: page.Clicks,
              downloads: page.Downloads,
              tagData: <span  style={{backgroundColor: page?.tag?.color,
                color: 'rgb(0, 0, 0)',
                borderRadius: '45px',
                padding: '5px 20px',
                margin: '0px 2px',
                display: 'inline-block'}}>{page?.tag?.titleEn}</span>
                ,
          
              
              
              actions: (
                <div className="d-flex">
                  <a
                    style={{...editBtn, marginRight: "10px"}}
                    href={page.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="bx bx-link fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    View
                  </a>

                  <button
                    style={editBtn}
                    onClick={() => {
                      setActive(true)
                      setNews(page)
                    }}
                  >
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>
                  {user?.roleAccess[3]?.Delete&&<button
                    style={deleteBtn}
                    onClick={e => deleteNews(page.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>}
                </div>
              ),
              createdOn:page.created_at ? page.created_at : <span className="fs-4">-</span>,
            }
          })
        )
      }).catch(err => {
        toast.error("Something went wrong")
      })
  }

  let GetData = (page)=> {
    axios.get(`${process.env.REACT_APP_API}/dashboard/blogs?page=${page}`, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      setTotalSize(res.data.data.pagination.total)
      setProductData(
        res.data.data.Blogs.map(page => {
          return {
            ...page,
              category:page.Category.nameEn?page.Category.nameEn:<span className="fs-4">-</span>, 
        
        

              titleEn: page.titleEn?page.titleEn:<span className="fs-4">-</span>,
              titleAr: page.titleAr?page.titleAr:<span className="fs-4">-</span>,
              authorEn: page.authorEn?page.authorEn:<span className="fs-4">-</span>,
              authorAr: page.authorAr?page.authorAr:<span className="fs-4">-</span>,
              
            actions: (
              <div className="d-flex">
                 <a
                  style={{ ...editBtn, marginRight: "10px" }}
                  href={page.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="bx bx-link fs-4 pr-1"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  View
                </a>
                <button style={editBtn} onClick={() => {
                  setActive(true)
                  setNews(page)
                }}>
                  <i
                    className="bx bx-edit fs-4 pr-1"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  Edit
                </button>
                {user?.roleAccess[3]?.Delete&&<button
                  style={deleteBtn}
                  onClick={e => deleteNews(page.id)}
                >
                  <i
                    className="bx bx-trash fs-4"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  Delete
                </button>}
              </div>
            ),
            createdOn: page.created_at ? (
              page.created_at
            ) : (
              <span className="fs-4">-</span>
            ),
          }
        })
      )
    }).catch(err => {
      toast.error("Something went wrong")
    })
  }

  useEffect(() => {
    dispatch(setTitle("News"))
    GetData(page)
  }, [page, filter])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Layouti Resource | Pages Details</title>
        </MetaTags>
        <div className="container-fluid p-0">
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            ></SweetAlert>
          ) : null}
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
           {active&&<NewsForm
            active={active}
            setActive = {setActive}
            news = {news}
            GetData={GetData}
            page={page}
            setNews = {setNews}
          />}
          
          <Card>
            <CardBody>
            
          <Row>
            <Col>
            <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                              <div className="save__btn top mb-2">
                                  <button onClick={() => {
                                    setActive(true)
                                    setNews(null)
                                  }}>
                                    <i className="bx bx-plus-circle fs-4" /> Add News
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    remote
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    onTableChange={onPageHandler}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0?<div className="emptyStateTable" style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      fontSize: '16px',
                                      padding: '10px',
                                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0?<Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>:null}
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }
                    
                    </PaginationProvider>
            </Col>
          </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewsTable

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
