/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link, useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Select from "react-select"
import "../specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const AddCategoryFormSubResources = ({category, getData, active, setActive, isSubCategory, setCategory,categories,filter,getProductsData,setFilter,clickHandle}) => {
  const history = useHistory();
  const [isOpen, setisOpen] = useState(false)
  const [name_en, setNameEn] = useState("")
  const [name_ar, setNameAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [mainCategory, setMainCategory] = useState("")
  const [prev, setPrev] = useState(null)
  const [facebookImage, setFacebookImage] = useState('')
  const [categoryData, setCategoryData] = useState([])
  const [nameEnError, setNameEnError] = useState(null)
  const [descriptiopnEnError, setDescriptiopnEnError] = useState(null)
  const [nameArError, setNameArError] = useState(null)
  const [descriptiopnArError, setDescriptiopnArError] = useState(null)
  const [iconError, setIconError] = useState(null)

  function handleFacebookFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setFacebookImage(Object.values(files)[0])
    e.target.value = ""
  }
  
  function deleteSelectImage(){
    setFacebookImage(null)
  }

  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }
  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      descriptionEn: descEn,
      descriptionAr: descAr,
      icon: facebookImage,
      category: filter,
      id: category?.id,
    }

    for (let key in data) {
      formData.append(key, data[key])
    }
    let reqLink = category ? `update` : `add`
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/configurations/resourceSubCategories/${reqLink}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
          toast.success("Category Added or Updated Successfully!", {position: "top-right", theme: "colored"})
          getProductsData(filter)
          setLoading(false)
          setActive(false)
          setCategory(null)
          clickHandle(null,false)
      })
      .catch(err => {
        setLoading(false)
        if(err.response.data.error != null){
          if(err.response.data.error.icon != null){
            err.response.data.error.icon.map(error =>{
              
              setIconError(error)
            })
          }
          if(err.response.data.error.descriptionEn != null){
            err.response.data.error.descriptionEn.map(error =>{
              setDescriptiopnEnError(error)
            })
          }
          if(err.response.data.error.nameEn != null){
            err.response.data.error.nameEn.map(error =>{
              setNameEnError(error)
            })
          }
          if(err.response.data.error.descriptionAr != null){
            err.response.data.error.descriptionAr.map(error =>{
              setDescriptiopnArError(error)
            })
          }
          if(err.response.data.error.nameAr != null){
            err.response.data.error.nameAr.map(error =>{
              setNameArError(error)
            })
          }
        }
      })
  }


  useEffect(() => {
    if (category) {
      console.log(category)
      setNameEn(category.nameEn)
      setNameAr(category.nameAr)
      setDescEn(category.descriptionEn)
      setDescAr(category.descriptionAr)
      setFacebookImage(category.icon)
      return
    }
  }, [])

  function clickHandleForm(index) {
    setFilter(index)
    getProductsData(index)

  }

  useEffect(() => {
    categories&&setCategoryData(categories)
  }, [])

  return (
    <div className={`overlay_coup ${active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "550px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            Category
          </div>
          <button onClick={() => (setActive(false), setCategory(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          
            <Row className="mt-1">
            <Col>
              <div>
                <Label htmlFor="productdesc">Main Category</Label>
              
                <select className="form-control input_cus" value={filter} onChange={(e) => clickHandleForm(e.target.value)} >
                  {categories.map((cat) => <option key={cat.id} value={cat.id}>{cat.nameEn}</option>)}
                </select>
              </div>
            </Col>
          </Row>
           

          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (English)</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                  style={{ border: nameEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                />
                {
                  nameEnError ? 
                  <span className="fs-6 text-danger">
                  { nameEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (Arabic)</Label>
                <Input
                  name="nameAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                  style={{ border: nameArError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                />
                {
                  nameArError ? 
                  <span className="fs-6 text-danger">
                  { nameArError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row className="">
            <Col sm="12">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  name="descriptionEn"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={descEn}
                  onChange={e => setDescEn(e.target.value)}
                  style={{ border: descriptiopnEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                />
              </div>
              {
                  descriptiopnEnError ? 
                  <span className="fs-6 text-danger">
                  { descriptiopnEnError }
                  </span> : ''
                }
            </Col>
            <Col sm="12" className="mt-3">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  name="descriptionAr"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={descAr}
                  onChange={e => setDescAr(e.target.value)}
                  style={{ border: descriptiopnArError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  dir="rtl"
                />
              </div>
              {
                  descriptiopnArError ? 
                  <span className="fs-6 text-danger">
                  { descriptiopnArError }
                  </span> : ''
                }
            </Col>
          </Row>
          <Row className="mt-3">
              <Col xs="12">
                <div>
                  <Label htmlFor="productdesc">Icon</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    name='attachmentImage'
                    onChange={e => handleFacebookFiles(e, e.target.files)}
                    style={{ borderRadius: 0,border: iconError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                    // multiple
                  />
                </div>
                {
                  iconError ? 
                  <span className="fs-6 text-danger">
                  { iconError }
                  </span> : ''
                }
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {facebookImage ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={facebookImage.name}
                              src={facebookImage.preview ?facebookImage.preview: facebookImage }
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {facebookImage.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(e, facebookImage.preview)
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e => deleteSelectImage()}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
          </Row>
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={() => (setActive(false), setCategory(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AddCategoryFormSubResources
