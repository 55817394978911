/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import "react-image-lightbox/style.css"

const DashTable = props => {
  const [productData, setProductData] = useState([])
  const [columns, setColumns] = useState([
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
  ])

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length,
    custom: true,
  }

  useEffect(() => {
    setColumns(props.columns)
    setProductData(props.data)
  }, [props])

  return (
    <Card>
      <CardBody className="pb-0">
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="id"
          columns={columns}
          data={productData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={productData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <h3 className="fs-5">{props.title}</h3>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"id"}
                          responsive
                          bordered={false}
                          striped={false}
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {productData.length === 0 ? (
                          <div
                            className="emptyStateTable"
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "16px",
                              padding: "10px",
                              paddingTop: "0",
                              paddingBottom: "20px",
                            }}
                          >
                            no data available in table
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

export default DashTable
