import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    Row,
    TabContent,
    TabPane,

} from "reactstrap";
import Sticky from 'react-sticky-el';
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../store/actions";
import { NavLink, useHistory } from "react-router-dom";
import classnames from "classnames";


//pages import
import HeaderContentPage from "./Pages/HeaderContent";
import FooterContentPage from "./Pages/FooterContent";
import HeaderContentHome from "./Pages/HeaderContentHome";

const ResourcesPages = () => {
    const dispatch = useDispatch()
    const history  = useHistory()
    const user = useSelector(state => state.UserData.user)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };


    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/dashboard')
            return;
        } 
    }, [user])



    useEffect(() => {   
        dispatch(setTitle("Static Page"))
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Resources | Etoy App</title>
                </MetaTags>
                <Container fluid>
                    {/* <Card>
                        <CardBody>
                            <HeaderContentPage />
                            <FooterContentPage />
                        </CardBody>
                    </Card> */}
                    <Col>
            <Row>
                <Col md="3">
                    <Sticky topOffset={-300} >
                        <Nav pills className="flex-column justify-content-between navs_aside">
                            <NavItem  className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    "": true,
                                    active: verticalActiveTab === "1",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("1");
                                }}
                                >
                                Home Page

                                </div>
                            </NavItem>
                            <NavItem  className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    "": true,
                                    active: verticalActiveTab === "2",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("2");
                                }}
                                >
                                    Main Page

                                </div>
                            </NavItem>
                            <NavItem  className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    "": true,
                                    active: verticalActiveTab === "3",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("3");
                                }}
                                >
                                    Footer Page

                                </div>
                            </NavItem>

                          
                           
                        </Nav>
                    </Sticky>
                </Col>

                <Col md="9">
                    <Card>
                        <CardBody>
                            <TabContent
                            activeTab={verticalActiveTab}
                            className="text-muted table__content mt-4 mt-md-0"
                            >
                                <TabPane tabId="1">
                                  <HeaderContentHome />
                                </TabPane>
                                <TabPane tabId="2" >
                                    <HeaderContentPage />
                                </TabPane>
                                <TabPane tabId="3">
                                    <FooterContentPage />
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Col>
                </Container>
                
            </div>
        </React.Fragment>
)
}

export default ResourcesPages



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}