import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Col, Row, Container } from "reactstrap"
import Cards from "./Cards"
import DashTable from "./DashTable"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import CountryTable from "./CountryTable"

function Dashboard() {
  const dispatch = useDispatch()
  const categories = useSelector(state => state.MainData.categories)
  const [data, setData] = useState(null)
  const [info, setInfo] = useState([])
  const [info2, setInfo2] = useState([])
  const [total, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "nameEn",
      text: "Name (English)",
      sort: true,
    },
    {
      dataField: "layoutiCount",
      text: "# of Layouti Websites",
      sort: true,
    },
    {
      dataField: "activeCount",
      text: "# of Active Websites",
      sort: true,
    },
    {
      dataField: "subCategoriesCount",
      text: "# of SubCategories",
      sort: true,
    },
    {
      dataField: "Viwers",
      text: "# of Views",
      sort: true,
    },
    {
      dataField: "Downloads",
      text: "# of Downloads",
      sort: true,
    },
    {
      dataField: "Likes",
      text: "# of Likes",
      sort: true,
    },
    {
      dataField: "pages",
      text: "# of Websites",
      sort: true,
    },
    {
      dataField: "Visitors",
      text: "# of Visitors",
      sort: true,
    },
  ]

  const userColumns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "nameEn",
      text: "Name (English)",
      sort: true,
    },
    {
      dataField: "nameAr",
      text: "Name (Arabic)",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "todayCount",
      text: "Today",
      sort: true,
    },
    {
      dataField: "allCount",
      text: "All",
      sort: true,
    },
    {
      dataField: "pages",
      text: "Pages",
      sort: true,
    },
  ]

  function getData() {
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/resources/analysis/cards`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setData(res.data.data)
      })
      .catch(err => {})
  }

  function getInfo() {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/analysis/categories`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setInfo(res.data.data?.Categories)
      })
      .catch(err => {})
  }

  function getInfo2() {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/analysis/Countries?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setInfo2(res.data.data?.Countries)
        res.data.data.pagination.total !== 0
          ? setTotalCount(res.data.data.pagination.total)
          : null
      })
      .catch(err => {})
  }

  useEffect(() => {
    getData()
    getInfo()
    getInfo2()
    dispatch(setTitle("Dashboard"))
  }, [categories])

  useEffect(() => {
    getInfo2()
  }, [page])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | ResourceHub</title>
        </MetaTags>
        <Container fluid>
          <Cards data={data} />

          <h2 className="fs-3 mt-4 fw-bolder text-black mb-3">Tables Data</h2>
          <Row>
            <Col>
              <DashTable title={"Categories"} columns={columns} data={info} />
            </Col>
          </Row>
          <Row>
            <Col>
              <CountryTable
                title={"Countries"}
                columns={userColumns}
                data={info2}
                total={total}
                setTotalCount={setTotalCount}
                page={page}
                setPage={setPage}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
