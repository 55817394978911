import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import "./configration.scss"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { setTitle } from "../../../store/actions"
import NewsCategory from "./Components/NewsCategory"


const ResourcesOptions = () => {
  const dispatch = useDispatch()
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [tags, setTags] = useState([])
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const user = useSelector(state => state.UserData.user)
  
  
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  

  useEffect(() => {
    dispatch(setTitle("News Configurations"))
  
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Etoy App</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              {/* <Col md="3">
                <Sticky topOffset={-300} >
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                         Categories
                      </NavLink>
                    </NavItem>
                    

                  </Nav>
                </Sticky>
              </Col> */}
              <Col md="12">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <NewsCategory
                      roles={user?.roleAccess[3]}

                    />
                  </TabPane>
                  
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResourcesOptions

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
