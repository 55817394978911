/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link, useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "../specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const AddCategoryFormMainResources = props => {
  let p = props
  const history = useHistory()
  const [isOpen, setisOpen] = useState(false)
  const [name_en, setNameEn] = useState("")
  const [nameEnError, setNameEnError] = useState(null)
  const [descriptiopnEnError, setDescriptiopnEnError] = useState(null)
  const [iconError, setIconError] = useState(null)
  const [name_ar, setNameAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [prev, setPrev] = useState(null);
  const [facebookImage, setFacebookImage] = useState('');
  
  
  function handleFacebookFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setFacebookImage(Object.values(files)[0])
    e.target.value = ""
  }
  
  function deleteSelectImage(){
    setFacebookImage(null)
  }

  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }

  function RealTimeUpdateData (data) { 
    props.setProductData(
      data?.map(category => {
        return {
          ...category,
          actions: (
            <div className="d-flex">
              <button
                style={editBtn}
                onClick={() => {
                  props.setActive(true)
                  props.setCategory(category)
                }}>
                
                <i
                  className="bx bx-edit fs-4 pr-1"
                  style={{ marginRight: "10px" }}
                ></i>{" "}
                Edit
              </button>
              <button
                style={deleteBtn}
                onClick={e => props.deleteCategory(category.id)}
              >
                <i
                  className="bx bx-trash fs-4"
                  style={{ marginRight: "10px" }}
                ></i>{" "}
                Delete
              </button>
            </div>
          ),
        }
      })
    )
  }



  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      descriptionEn: descEn,
      descriptionAr:descAr,
      icon: facebookImage,
      id,
    }

    for (let key in data) {
      formData.append(key, data[key])
    }
    let reqLink = props.category? `update`: `add`
    axios.post( `${process.env.REACT_APP_API}/dashboard/configurations/resourceCategories/${reqLink}`,formData,{
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    })
      .then(res => {
        RealTimeUpdateData(res.data.data)
        toast.success(`Category ${props.category?'Updated':"Added"} Success`, {position: "top-right"})
        setLoading(false)
        props.setActive(false)
        props.setCategory(null)
      })
      .catch((error)=>{
        console.log(error.response.data.error);
          setLoading(false)
          if(error.response.data.error != null){
            if(error.response.data.error.icon != null){
              error.response.data.error.icon.map(error =>{
                setIconError(error)
              })
            }
            if(error.response.data.error.descriptionEn != null){
              error.response.data.error.descriptionEn.map(error =>{
                setDescriptiopnEnError(error)
              })
            }
            if(error.response.data.error.nameEn != null){
              error.response.data.error.nameEn.map(error =>{
                setNameEnError(error)
              })
            }
          }
      })
    
  }

  useEffect(() => {
    if (p.category) {
      setNameEn(p.category.nameEn)
      setNameAr(p.category.nameAr)
      setDescEn(p.category.descriptionEn)
      setDescAr(p.category.descriptionAr)
      setDescAr(p.category.descriptionAr)
      setId(p.category.id)
      setFacebookImage(p.category.icon)
      setDescriptiopnEnError(null)
      setNameEnError(null)
      setIconError(null)
      return
    }
    
  }, [p.category])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "550px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            Category
          </div>
          <button onClick={() => (props.setActive(false), props.setCategory(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          
          {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
         
            

          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (English)</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                  style={{ border: nameEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  nameEnError ? 
                  <span className="fs-6 text-danger">
                    { nameEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (Arabic)</Label>
                <Input
                  name="nameAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                
              </div>
            </Col>
          </Row>

          <Row className="">
            <Col sm="12">
              <div>
                <div className="d-flex">
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <label className="ms-auto optional-label--color">
                    (Max 125 Char)
                  </label>
                </div>
                <textarea
                  name="descriptionEn"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={descEn}
                  onChange={e => setDescEn(e.target.value)}
                  maxLength="125"
                  style={{ border: descriptiopnEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}

                />
                 {
                  descriptiopnEnError ? 
                  <span className="fs-6 text-danger">
                  { descriptiopnEnError }
                  </span> : ''
                }
              </div>
            </Col>
            <Col sm="12" className="mt-3">
              <div>
                <div className="d-flex">
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <label className="ms-auto optional-label--color">
                    (Max 125 Char)
                  </label>
                </div>
                <textarea
                  name="descriptionAr"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={descAr}
                  onChange={e => setDescAr(e.target.value)}
                  dir="rtl"
                  maxLength="125"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
              <Col xs="12">
                <div>
                  <Label htmlFor="productdesc">Icon</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    name='attachmentImage'
                    onChange={e => handleFacebookFiles(e, e.target.files)}
                    style={{ borderRadius: 0,border: iconError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                    // multiple
                  />
                   {
                    iconError ? 
                    <span className="fs-6 text-danger">
                    { iconError }
                    </span> : ''
                  }
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {facebookImage ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={facebookImage.name}
                              src={facebookImage.preview ?facebookImage.preview: facebookImage }
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {facebookImage.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(e, facebookImage.preview)
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e => deleteSelectImage()}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>

              </Col>
          </Row>
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={() => (props.setActive(false), props.setCategory(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}

export default AddCategoryFormMainResources
