/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link, useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "../specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const SocialPostCategoryForm = props => {
  let p = props
  const history = useHistory()
  const [isOpen, setisOpen] = useState(false)
  const [name_en, setNameEn] = useState("")
  const [nameEnError, setNameEnError] = useState(null)
  const [name_ar, setNameAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [prev, setPrev] = useState(null);
  const [color, setColor] = useState("#000000")
  const [colorError, setColorError] = useState(null)


  
  
  
  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      color : color,
      id,
    }

    for (let key in data) {
      formData.append(key, data[key])
    }
    let reqLink = props.category? `update`: `add`
    axios.post( `${process.env.REACT_APP_API}/dashboard/socialPost/category/${reqLink}`,formData,{
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    })
      .then(res => {
        props.getNewsData()
        toast.success(`Category ${props.category?'Updated':"Added"} Success`, {position: "top-right"})
        setLoading(false)
        props.setActive(false)
        props.setCategory(null)
      })
      .catch((error)=>{
        console.log(error.response.data.error);
          setLoading(false)
          if(error.response.data.error != null){
            
            
            if(error.response.data.error.nameEn != null){
              error.response.data.error.nameEn.map(error =>{
                setNameEnError(error)
              })
            }
            if(error.response.data.error.color != null){
              error.response.data.error.color.map(error =>{
                setColorError(error)
              })
            }
          }
      })
    
  }

  useEffect(() => {
    if (p.category) {
      setNameEn(p.category.nameEn)
      setNameAr(p.category.nameAr)
      setId(p.category.id)
      setNameEnError(null)
      setColorError(null)
      setColor(p.category.color)
      return
    }
    
  }, [p.category])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "430px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            Category
          </div>
          <button onClick={() => (props.setActive(false), props.setCategory(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          
          {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
         
            

          <Row className="">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (English)</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                  style={{ border: nameEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  nameEnError ? 
                  <span className="fs-6 text-danger">
                    { nameEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (Arabic)</Label>
                <Input
                  name="nameAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                
              </div>
            </Col>
          </Row>
          <Row className="mb-1">
          <Col>
            <div className="d-flex">
              <Label htmlFor="color">Color</Label>
            </div>
            <div className="d-flex">
              <input
                type="color"
                className="form-control colorInput"
                name="backGroundColor"
                value={color}
                onChange={e =>
                  setColor(e.target.value)
                }
              />
              <Input
                type="text"
                className="color__name rounded-0"
                name="backGroundColor"
                value={color}
                onChange={e =>
                  setColor(e.target.value)
                }
              />
              {
                  colorError ? 
                  <span className="fs-6 text-danger">
                    { colorError }
                  </span> : ''
                }
            </div>
          </Col>
        </Row>

         
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={() => (props.setActive(false), props.setCategory(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}

export default SocialPostCategoryForm
