import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import "react-image-lightbox/style.css"

const CountryTable = props => {
  const [productData, setProductData] = useState([])
  const [columns, setColumns] = useState([
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
  ])

  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length,
    custom: true,
  }
  const onPageHandler = (type, { page }) => {
    props.setPage(page)
  }
  useEffect(() => {
    setColumns(props.columns)
    setProductData(
      props?.data?.map(data => {
        return {
          ...data,
          pages: data.pages.map((page, index) => (
            <span
              key={index}
              style={{
                backgroundColor: "#ccc",
                color: "rgb(0, 0, 0)",
                borderRadius: "45px",
                padding: "5px 20px",
                margin: "0px 2px",
                display: "inline-block",
              }}
            >
              {page}
            </span>
          )),
        }
      })
    )
  }, [props])

  return (
    <Card>
      <CardBody className="pb-0">
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: props.page,
            sizePerPage: 10,
            totalSize: props.total,
          })}
          
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
            keyField={"id"}
              columns={columns}
              data={productData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <h3 className="fs-5">{props.title}</h3>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          remote
                          responsive
                          bordered={false}
                          onTableChange={onPageHandler}
                          striped={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {productData.length === 0 ? (
                          <div
                            className="emptyStateTable"
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "16px",
                              padding: "10px",
                              paddingTop: "0",
                              paddingBottom: "20px",
                            }}
                          >
                            no data available in table
                          </div>
                        ) : null}
                        {productData.length !== 0 ? (
                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

export default CountryTable
