import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
//i18n
import { withTranslation } from "react-i18next"
import { Badge } from "reactstrap"
const SidebarContent = props => {
  const ref = useRef()
  const notifications = useSelector(state => state.Notifications)
  const MainData = useSelector(state => state.MainData)
  const [notify, setNotify] = useState({
    notifications: [],
    userCount: 0,
    toyCount: 0,
    notifyCount: 0,
    suggestionCount: 0,
    feedbackCount: 0,
    reviewCount: 0,
    reportCount: 0,
  })
  const user = useSelector(state => state.UserData.user)
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  useEffect(() => {
    setNotify(notifications)
  }, [notifications])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("General")} </li>
            <li>
              <Link to="/dashboard">
                <i className="bx bxs-dashboard"></i>
                <span style={{ fontSize: "14px" }}>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Resources")} </li>

            <li>
              <Link to="/resources/user">
                <i className="bx bx-user"></i>
                <span style={{ fontSize: "14px" }}>{props.t("Users")}</span>
                {notifications?.userCount ? (
                  <Badge color="danger" style={{ float: "right" }} pill>
                    {notifications?.userNotificationsCount}
                  </Badge>
                ) : null}
              </Link>
            </li>

            <li>
              <Link to="/resources/layouti">
                <i className="fas fa-home"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Layouti Resources")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/resources">
                <i className="far fa-check-circle"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Active Resources")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/resources/detailsPage/pending">
                <i className="far fa-clock"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Pending Resources")}
                </span>
                {notifications?.pendingCount ? (
                  <Badge color="danger" style={{ float: "right" }} pill>
                    {notifications?.pendingCount}
                  </Badge>
                ) : null}
              </Link>
            </li>
            <li>
              <Link to="/resources/resourcesPages">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Static Page")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/resources/seo">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>{props.t("SEO")}</span>
              </Link>
            </li>
            <li>
              <Link to="/resources/configurations">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Configurations")}
                </span>
              </Link>
            </li>

            {/**********************News*********************/}
            <li className="menu-title">{props.t("News")} </li>
            <li>
              <Link to="/resources/news">
                <i className="bx bx-category"></i>
                <span style={{ fontSize: "14px" }}>{props.t("News")}</span>
              </Link>
            </li>
            <li>
              <Link to="/resources/news/seo">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>{props.t("SEO")}</span>
              </Link>
            </li>
            <li>
              <Link to="/resources/news/configurations">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Configurations")}
                </span>
              </Link>
            </li>

            {/**********************Cahanels*********************/}
            <li className="menu-title">{props.t("Channels")} </li>
            <li>
              <Link to="/resources/channels/videos">
                <i className="bx bx-video"></i>
                <span style={{ fontSize: "14px" }}>{props.t("Vedios")}</span>
              </Link>
            </li>
            <li>
              <Link to="/resources/channels/seo">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>{props.t("SEO")}</span>
              </Link>
            </li>
            <li>
              <Link to="/resources/pages">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Static Page")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/resources/channels">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Configurations")}
                </span>
              </Link>
            </li>

            {/**********************Social Post*********************/}
            <li className="menu-title">{props.t("Social Post")} </li>
            <li>
              <Link to="/resources/socialPost/design">
                <i className="bx bx-category"></i>
                <span style={{ fontSize: "14px" }}>{props.t("Designs")}</span>
              </Link>
            </li>
            <li>
              <Link to="/resources/socialPost/seo">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>{props.t("SEO")}</span>
              </Link>
            </li>
            <li>
              <Link to="/resources/socialPost/configurations">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Configurations")}
                </span>
              </Link>
            </li>

            {/**********************Settings*********************/}
            <li className="menu-title">{props.t("Settings")} </li>
            <li>
              <Link to="/adminsetting">
                <i className="bx bx-cog"></i>
                <span style={{ fontSize: "14px" }}>{props.t("Settings")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
