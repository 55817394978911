import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import Admins from "./Components/Admins"
import Category from "./Components/Category"
import EmailShot from "./Components/EmailShot"
import CharacterPdf from "./Components/CharacterPdf"
import "./configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import { useHistory } from "react-router-dom"
import Author from "./Components/Author"
import Deliverable from "./Components/Deliverable"
import ScopeOfWork from "./Components/ScopeOfWork/ScopeOfWork"
import Budget from "./Components/Budget"
import WhatWeNeed from "./Components/WhatWeNeed"
import axiosConfig from "axiosConfig"
import CategoryForLayouti from "./Components/CategoryForLayouti"
import CategoryForFAQ from "./Components/CategoryForFAQ"
import CategoryForBlog from "./Components/CategoryForBlog"
import CategoryFor365Design from "./Components/CategoryFor365Design"
import CategoryForMainResources from "./Components/CategoryForMainResources"
import CategoryForSubResources from "./Components/CategoryForSubResources"
import CategoryForLayoutiWork from "./Components/CategoryForLayoutiWork"
import TagFilterResources from "./Components/TagFilterResources"

const Options = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()

  const [data, setData] = useState([])
  const [admins, setAdmins] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [email, setEmail] = useState(null)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function userChanges(user, type) {
    if (type === "active") {
      setActive(users => users.concat(user))
      setUnActive(users => users.filter(u => u.key !== user.key))
    } else if (type === "unactive") {
      setActive(users => users.filter(u => u.key !== user.key))
      setUnActive(users => users.concat(user))
    } else if (type === "del") {
      setActive(users => users.filter(u => u.key !== user.key))
    } else {
      setPending(users => users.filter(u => u.key !== user.key))
      setUnActive(users => users.concat(user))
    }
  }

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/")
      return
    }
  }, [user])

  const [layoutiCategories, setLayoutiCategories] = useState([])
  const [layoutiCategoriesWorks, setLayoutiCategoriesWorks] = useState([])
  const [layoutiCategoriesFAQ, setLayoutiCategoriesFAQ] = useState([])
  const [design365, setDesign365] = useState([])
  const [scopeOfWork, setScopeOfWork] = useState([])
  const [deliverables, setDeliverables] = useState([])
  const [blogData, setBlogData] = useState([])
  const [authors, setAuthors] = useState([])

  function getResourceMainCategoriesData() {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/configurations/resourceCategories`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setCategories(res?.data?.data)
      })
      .catch(err => {
      })
  }

  function getResourceSubCategoriesData() {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/configurations/resourceSubCategories`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setSubCategories(res?.data?.data)
      })
      .catch(err => {
      })
  }

  function getLayoutiData() {
    axiosConfig
      .get(`/dashboard/configurations/layoutiCategoriesServices`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setLayoutiCategories(res.data.data)
      })
      .catch(err => {
      })
  }
  function get365DesignData() {
    axiosConfig
      .get(`/dashboard/365Design/category`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setDesign365(res.data.data)
      })
      .catch(err => {
      })
  }
  function getBlogData() {
    axiosConfig
      .get(`/dashboard/blog/category`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setBlogData(res.data.data)
      })
      .catch(err => {
      })
  }
  function getLayoutiDataWorks() {
    axiosConfig
      .get(`/dashboard/configurations/layoutiCategories`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setLayoutiCategoriesWorks(res.data.data)
      })
      .catch(err => {
      })
  }
  function getFaqData() {
    axiosConfig
      .get(`/dashboard/configurations/layoutiCategoriesFaqs`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setLayoutiCategoriesFAQ(res.data.data)
      })
      .catch(err => {
      })
  }
  function getAuthors() {
    axiosConfig
      .get(`/dashboard/blog/author`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setAuthors(res.data.data)
      })
      .catch(err => {
      })
  }
  function getScopeOfWork() {
    axiosConfig
      .get(`/dashboard/configurations/scopes`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setScopeOfWork(res.data.data)
      })
      .catch(err => {
      })
  }
  function getDeliverables() {
    axiosConfig
      .get(`/dashboard/configurations/deliverable`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setDeliverables(res.data.data)
      })
      .catch(err => {
      })
  }

  useEffect(() => {
    dispatch(setTitle("configurations"))
    getResourceMainCategoriesData()
    getResourceSubCategoriesData()
    getLayoutiDataWorks()
    get365DesignData()
    getBlogData()
    getLayoutiData()
    getAuthors()
    getFaqData()
    getScopeOfWork()
    getDeliverables()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Etoy App</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              <Col md="3">
                <Sticky topOffset={-300} >
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >

                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Categories For Blog
                      </NavLink>
                    </NavItem>



                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "5",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("5")
                        }}
                      >
                        Authors
                      </NavLink>
                    </NavItem>

                  </Nav>
                </Sticky>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  
           
                  {/* blog */}
                  <TabPane tabId="1">
                    <CategoryForBlog
                      categories={blogData}
                      getData={getBlogData}
                      roles={user?.roleAccess[3]}
                    />
                  </TabPane>
                 
                  <TabPane tabId="2">
                    <CategoryForMainResources
                      categories={categories}
                      getData={getResourceMainCategoriesData}
                      isSubCategory={false}
                      roles={user?.roleAccess[3]}

                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <CategoryForSubResources
                      categories={subCategories}
                      getData={getResourceSubCategoriesData}
                      isSubCategory={true}
                      roles={user?.roleAccess[3]}

                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <Author categories={authors} getData={getAuthors} />
                  </TabPane>
               
                  <TabPane tabId="0">
                    <TagFilterResources />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Options

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
