import {
  GET_TITLE
} from "./actionTypes"

const INIT_STATE = {
  title: "Dashboard",
}

const Title = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TITLE:
      return {
        ...state,
        title: action.payload,
      }
    default:
      return state
  }
}

export default Title