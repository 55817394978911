import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  var formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  var config = {
    method: 'post',
    url: 'https://master.etoyapp.com/api/login',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : formData
  };  
  return axios(config)
  .then(response => {
      let user = response.data.data.user
      let authToken = response.data.data.token
      if(!user) {
        return;
      }
      return {...user, authToken}
  })
  .catch(function (error) {
    console.log(error);
  });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, JSON.stringify({ ...data }), { ...config })
    .then(response => response.data)
    
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
