import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/General/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/General/Authentication/Login"

// Dashboard

import OtionsSettings from "pages/Settings/AdminSettings/OtionsSettings"
import ResourcesPages from "pages/Resources/ResourcesPages"
import InnerPageResourceTable from "pages/Resources/InnerPageResourceTable"
import InnerPageLayoutiResourceTable from "pages/Resources/InnerPageLayoutiResourceTable"
import InnerPagePendingResourceTable from "pages/Resources/InnerPagePendingResourceTable"
import AddInnerPageResourses from "pages/Resources/AddInnerPageResource"
import EditInnerPageResource from "pages/Resources/EditInnerPageResource"
import EditInnerPendingPageResource from "pages/Resources/EditInnerPendingPageResource"
import SeoPage from "pages/Resources/SeoPage/SeoPage"
import Options from "pages/Settings/Options/Options"
import ResourcesOptions from "pages/Resources/Options/ResourcesOptions"
import ResourcesAnalysis from "pages/Resources/Analysis/index"
import User from "pages/User/User"

import NewsOptions from "pages/News/Options/NewsOptions"
import News from "pages/News/Data/NewsTable"
import NewsSeoPage from "pages/News/SeoPage/NewsSeoPage"

import ChannelsOptions from "pages/Channels/Options/ChannelsOptions"
import VideosTable from "pages/Channels/Data/VideosTable"
import ChannelSeoPage from "pages/Channels/SeoPage/ChannelSeoPage"
import ChannelStaticPages from "pages/Channels/Pages/ChannelStaticPages"

import SocialPostOptions from "pages/SocailPost/Category/SocialPostOptions"
import Post from "pages/SocailPost/Projects/Post"
import AddDesign from "pages/SocailPost/Projects/Components/Forms/AddDesign"
import EditDesign from "pages/SocailPost/Projects/Components/Forms/EditDesign"
import SocialPostSeoPage from "pages/SocailPost/SeoPage/SocialPostSeoPage"

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: ResourcesAnalysis },

  //profile
  { path: "/profile", component: UserProfile },

  //resources
  { path: "/resources", component: InnerPageResourceTable },
  { path: "/resources/analysis", component: ResourcesAnalysis },
  { path: "/resources/layouti", component: InnerPageLayoutiResourceTable },
  {
    path: "/resources/detailsPage/pending",
    component: InnerPagePendingResourceTable,
  },
  { path: "/resources/detailsPage/Add", component: AddInnerPageResourses },
  { path: "/resources/detailsPage/Edit", component: EditInnerPageResource },
  {
    path: "/resources/detailsPage/EditPending",
    component: EditInnerPendingPageResource,
  },
  { path: "/resources/resourcesPages", component: ResourcesPages },
  { path: "/resources/seo", component: SeoPage },
  { path: "/resources/configurations", component: ResourcesOptions },
  { path: "/resources/user", component: User },

  //News
  { path: "/resources/news", component: News },
  { path: "/resources/news/configurations", component: NewsOptions },
  { path: "/resources/news/seo", component: NewsSeoPage },

  //Channels
  { path: "/resources/channels/videos", component: VideosTable },
  { path: "/resources/channels", component: ChannelsOptions },
  { path: "/resources/channels/seo", component: ChannelSeoPage },
  { path: "/resources/pages", component: ChannelStaticPages },

  //SocialPost
  {
    path: "/resources/socialPost/configurations",
    component: SocialPostOptions,
  },
  { path: "/resources/socialPost/design", component: Post },
  { path: "/resources/socialPost/design/addDesign", component: AddDesign },
  {
    path: "/resources/socialPost/design/editDesign/:id",
    component: EditDesign,
  },
  { path: "/resources/socialPost/seo", component: SocialPostSeoPage },

  //Admin Settings
  { path: "/adminsetting", component: OtionsSettings },

  //configurations
  { path: "/configurations", component: Options },

  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [{ path: "/login", component: Login }]

export { publicRoutes, authProtectedRoutes }
