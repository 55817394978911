/* eslint-disable react/react-in-jsx-scope */
import axios from "axios"
import firebase from "firebase/app"
import "firebase/messaging"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getNotifications } from "store/actions"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBaiG1iSbURT3lvB2tkKq4o7EsH-dK0Spw",
  authDomain: "resourchub-1640b.firebaseapp.com",
  projectId: "resourchub-1640b",
  storageBucket: "resourchub-1640b.appspot.com",
  messagingSenderId: "23385715958",
  appId: "1:23385715958:web:449ca34e138b8853d1e8d7",
  measurementId: "G-D1V46Y7Y2S",
}

firebase.initializeApp(firebaseConfig)

const messaging = firebase.messaging()

const publicKey = process.env.REACT_APP_VAPID_KEY

export const getToken = async () => {
  let currentToken = ""
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey })
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error)
  }
  return currentToken
}

export function Fire() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)

  messaging.onMessage(payload => {
    console.log(payload)
    axios
      .get(
        `https://api.resourchub.com/api/dashboard/notifications/latest`,

        {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      )
      .then(res => {
        dispatch(
          getNotifications({
            notifications: res.data.data.notifications,
            notifyCount: res.data.data.notificationsCount,
            pendingCount: res.data.data.pendingNotificationsCount,
            userCount: res.data.data.userNotificationsCount,
            totOnline: res.data.data.totOnline,
          })
        )
      })
    payload?.data?.model
      ? null
      : toast(`${payload.notification.body}`, {
          icon: (
            <i className="fas fa-bell fs-4" style={{ color: "#ec7964" }}></i>
          ),
          theme: "dark",
        })
  })

  window.addEventListener("focus", () => {
    let notification = JSON.parse(sessionStorage.getItem("notification"))
  })

  return <></>
}
