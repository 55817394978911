/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { useHistory } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useSelector, useDispatch } from "react-redux"
import { setTitle, getProjects, getNotifications } from "../../store/actions"
import { toast } from "react-toastify"
import "rsuite/dist/rsuite.min.css"

const InnerPagePendingResourceTable = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [productData, setProductData] = useState([])
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const user = useSelector(state => state.UserData?.user)
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },

    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "country",
      text: "Counrty (English)",
      sort: true,
    },
    {
      dataField: "link",
      text: "Link",
      sort: true,
    },

    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "createdOn",
      text: "Created On",
      sort: true,
    },
  ]

  const pageOptions = {
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "75",
        value: 75,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: productData.length,
      },
    ],
  }

  const { SearchBar } = Search

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function clickHandle(id) {
    if (id) {
      return history.push(`/resources/detailsPage/EditPending?id=${id}`)
    }
  }

  function deleteAvatar(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage/deletePending`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(products => products.filter(prod => prod.id != id))
        setMsg("Pending Resource Deleted Successfuly !")
        setsuccess_msg(true)
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/notifications/ReadNotifications`,
        { model: "pending" },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        axios
          .get(
            `https://api.resourchub.com/api/dashboard/notifications/latest`,
            {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("authUser")).accessToken
                }`,
              },
            }
          )
          .then(res => {
            dispatch(
              getNotifications({
                notifications: res.data.data.notifications,
                notifyCount: res.data.data.notificationsCount,
                pendingCount: res.data.data.pendingNotificationsCount,
                userCount: res.data.data.userNotificationsCount,
              })
            )
          })
      })
  }, [])

  useEffect(() => {
    dispatch(setTitle("Pending Resources"))

    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage/pending?perPage=10&page=1`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(
          res.data.data.pages.map(page => {
            return {
              ...page,

              title: page.title,
              link: page.link,
              country: page.country.nameEn,

              actions: (
                <div className="d-flex">
                  <a
                    style={{ ...editBtn, marginRight: "10px" }}
                    href={page.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="bx bx-link fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    View
                  </a>
                  <button style={editBtn} onClick={() => clickHandle(page.id)}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>
                  {user?.roleAccess[3]?.Delete && (
                    <button
                      style={deleteBtn}
                      onClick={e => deleteAvatar(page.id)}
                    >
                      <i
                        className="bx bx-trash fs-4"
                        style={{ marginRight: "10px" }}
                      ></i>{" "}
                      Delete
                    </button>
                  )}
                </div>
              ),
              createdOn: page.created_at ? (
                page.created_at
              ) : (
                <span className="fs-4">-</span>
              ),
            }
          })
        )
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Resource | Pages Details</title>
        </MetaTags>
        <div className="container-fluid p-0">
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            ></SweetAlert>
          ) : null}
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody className="pb-0">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block ">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon mt-1 fs-3" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0 ? (
                                    <div
                                      className="emptyStateTable"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        padding: "10px",
                                        paddingTop: "0",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      no data available in table
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0 ? (
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : null}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InnerPagePendingResourceTable

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
