/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Select from "react-select"
import "./specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const AddCategoryFormSubResources = props => {
  let p = props
  const [isOpen, setisOpen] = useState(false)
  const [name_en, setNameEn] = useState("")
  const [name_ar, setNameAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [mainCategory, setMainCategory] = useState("")
  const [prev, setPrev] = useState(null)
  const [facebookImage, setFacebookImage] = useState('')
  const [categoryData, setCategoryData] = useState([])


  function handleFacebookFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setFacebookImage(Object.values(files)[0])
    e.target.value = ""
  }

  function deleteSelectImage() {
    setFacebookImage(null)
  }

  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }

  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      descriptionEn: descEn,
      descriptionAr: descAr,
      icon: facebookImage,
      category: mainCategory?.value,
      id,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    let reqLink = props.category ? `update` : `add`
    axiosConfig
      .post(
        `dashboard/configurations/resourceSubCategories/${reqLink}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        props.addCategorySuccsess()
        setLoading(false)
        setNameAr("")
        setNameEn("")
        setDescEn("")
        setDescAr("")
        setFacebookImage('')
        setMainCategory("")
        props.clickHandle(null, false)
      })
      .catch(err => {
        setLoading(false)
        toast.error("Something went wrong")
      })
  }

  function close() {
    document.getElementById("categForm").reset()
    setNameAr("")
    setNameEn("")
    setDescEn("")
    setDescAr("")
    setFacebookImage('')
    setMainCategory("")
    props.clickHandle()
  }

  useEffect(() => {
    axiosConfig
      .get(
        `dashboard/configurations/resourceCategories`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setCategoryData(res?.data?.data)
      })
      .catch(err => {
      })
  }, [])

  let optionGroup =[];
  categoryData?.map(l => {
    let ind = { label: l.nameEn, value: l.id }
    optionGroup.push(ind)
  })


  useEffect(() => {
    if (p.category) {
      setNameEn(p.category.nameEn)
      setNameAr(p.category.nameAr)
      setDescEn(p.category.descriptionEn)
      setDescAr(p.category.descriptionAr)
      setFacebookImage(p.category.icon)
      setMainCategory({ label: p.category?.category?.nameEn, value: p.category?.category?.id})
      setId(p.category.id)
      return
    }
  }, [p.category])
  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "550px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            Category
          </div>
          <button onClick={close}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          {p.isSubCategory && (
            <>
            <Row className="mt-1">
            <Col>
              <div>
                <Label htmlFor="productdesc">Main Category</Label>
                <Select
                  value={mainCategory}
                  isMulti={false}
                  onChange={e => {
                    setMainCategory(e)
                  }}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row>

            
            </>
          )}

          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (English)</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (Arabic)</Label>
                <Input
                  name="nameAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>

          <Row className="">
            <Col sm="12">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  name="descriptionEn"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={descEn}
                  onChange={e => setDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="12" className="mt-3">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  name="descriptionAr"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={descAr}
                  onChange={e => setDescAr(e.target.value)}
                  dir="rtl"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div>
                <Label htmlFor="productdesc">Icon</Label>
                <textarea
                  name="descriptionEn"
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={facebookImage}
                  onChange={e => setFacebookImage(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={close}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AddCategoryFormSubResources
