import PropTypes from "prop-types";
import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logos/Layouti Logo Light.svg";
import logoLightSvg from "../../assets/images/logos/Layouti Logo Light.svg";
import logoDark from "../../assets/images/logo-dark.png";

const Sidebar = props => {
  const mainData = useSelector(state => state.MainData.data)
  

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box p-0" style={{zIndex: "999999"}}>
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light " style={{textAlign: "left"}}>
            <span className="logo-sm">
              <img src={mainData?.icon} alt="" width="70%" style={{display: "block", margin: "10px auto", marginTop: "25px"}}/>
            </span>
            <span className="logo-lg ">
              <img src={mainData?.icon} alt="" width="60%" style={{display: "block", margin: "20px"}}/>
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100" style={{zIndex: "55555"}}>
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
