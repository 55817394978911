/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import "@pathofdev/react-tag-input/build/index.css"
import "react-image-lightbox/style.css"
import "../specification.scss"
import { toast } from "react-toastify"


const AddResourcesFilterTag = ({active, setActive, getResourceData, data, idData, setID}) => {
  const [nameEn, setNameEn] = useState("")
  const [nameAr, setNameAr] = useState("")
  const [color, setColor] = useState("#000000")
  const [loading, setLoading] = useState(false)
  const [nameEnError, setNameEnError] = useState(null)
  const [nameArError, setNameArError] = useState(null)


  function submitHandler() {
    setLoading(true)
    let data = {
      id:idData,
      titleEn: nameEn,
      titleAr: nameAr,
      color
    }
    axios.post(`${process.env.REACT_APP_API}/dashboard/configurations/resourceTags/${idData?'update':"add"}`, data, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`
        },
      })
      .then(res => {
          setLoading(false)
          setActive(false)
          setID(null)
          toast.success('Tag Added Or Updated Success', {theme: "light", position: "top-right"})
          getResourceData()
      })
      .catch(err => {
        setLoading(false)
        if(err.response.data.error){
          if(err.response.data.error.titleEn != null){
            err.response.data.error.titleEn.map(error =>{
              setNameEnError(error)
            })
          }
          if(err.response.data.error.titleAr != null){
            err.response.data.error.titleAr.map(error =>{
              setNameArError(error)
            })
          }
        }
      })
  }


  useEffect( () => {
    if(data && idData) { 
      let ele = data.filter(d => d.id == idData)[0]
      setNameEn(ele.titleEn)
      setNameAr(ele.titleAr)
      setColor(ele.color)
    }
  }, [data, idData])

  return (
    <div className={`overlay_coup ${active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "330px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            Tag Filter Resources
          </div>
          <button onClick={_ =>( setActive(false), setID(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">

          <Row>
            <Col md={6}>
            <div className="mb-3">
                <Label htmlFor="productdesc">Tag Name (English)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={nameEn}
                  style={{ border: nameEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  onChange={e => setNameEn(e.target.value)}
                />
                 {
                  nameEnError ? 
                  <span className="fs-6 text-danger">
                  { nameEnError }
                  </span> : ''
                }
                
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="productdesc">Tag Name (Arabic)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={nameAr}
                  style={{ border: nameArError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  onChange={e => setNameAr(e.target.value)}
                />
                {
                  nameArError ? 
                  <span className="fs-6 text-danger">
                  { nameArError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>
          <Row className="mb-1">
          <Col>
            <div className="d-flex">
              <Label htmlFor="colorID">Color</Label>
            </div>
            <div className="d-flex">
              <input
                type="color"
                className="form-control colorInput"
                name="backGroundColor"
                value={color}
                onChange={e =>
                  setColor(e.target.value)
                }
              />
              <Input
                type="text"
                className="color__name rounded-0"
                name="backGroundColor"
                value={color}
                onChange={e =>
                  setColor(e.target.value)
                }
              />
            </div>
          </Col>
        </Row>
        </div>

        

        <div className="foot_form">
          <button className="cancel" onClick={_ =>( setActive(false), setID(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AddResourcesFilterTag
