/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "./specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"

const AddAuthorForm = props => {
  let p = props
  const [isOpen, setisOpen] = useState(false)
  const [authorImg, setAuthorImg] = useState("")
  const [name_en, setNameEn] = useState("")
  const [name_ar, setNameAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [positionEn, setPositionEn] = useState("")
  const [positionAr, setPositionAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [facebookImage, setFacebookImage] = useState(null);
    
  function handleFacebookFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setFacebookImage(Object.values(files)[0])
    e.target.value = ""
  }
  function deleteSelectImage(){
    setFacebookImage(null)
  }

  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }

  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      descriptionEn:descEn,
      descriptionAr:descAr,
      positionEn:positionEn,
      positionAr:positionAr,
      id,
    }
    console.log(data)
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append('image', facebookImage)
    let reqLink = props.author? `update`: `add`
    axiosConfig.post( `dashboard/blog/author/${reqLink}`,formData,{
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    })
      .then(res => {
        setLoading(false)
        close()
        props.addCategorySuccsess()
        props.clickHandle(null,false)
      }).catch(err=>{
        setLoading(false)
        toast.error('Something went wrong')
      })
  }
  function close() {
    // document.getElementById("categForm").reset()
    setNameEn('')
    setNameAr('')
    setDescEn('')
    setDescAr('')
    setPositionEn('')
    setPositionAr('')
    setFacebookImage('')
    props.clickHandle()
  }

  useEffect(() => {
    if (p.author) {
      setNameEn(p.author.nameEn)
      setNameAr(p.author.nameAr)
      setDescEn(p.author.descriptionEn)
      setDescAr(p.author.descriptionAr)
      setPositionEn(p.author.positionEn)
      setPositionAr(p.author.positionAr)
      setFacebookImage(p.author.image)
      setId(p.author.id)
      return
    }
  }, [p.author])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "750px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            Add Author
          </div>
          <button onClick={close}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
        <Row className="mb-3">
              <Col xs="12">
                <div>
                  <Label htmlFor="productdesc">Attachement Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    name='attachmentImage'
                    onChange={e => handleFacebookFiles(e, e.target.files)}
                    style={{ borderRadius: 0 }}
                    // multiple
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {facebookImage ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={facebookImage.name}
                              src={facebookImage.preview?facebookImage.preview:facebookImage}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {facebookImage.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(e, facebookImage.preview?facebookImage.preview:facebookImage)
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e => deleteSelectImage()}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Author Name(English)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Author Name(Arabic)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <div className="mb-3">
                <Label htmlFor="productdesc">Author Position(English)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="ex. position"
                  value={positionEn}
                  onChange={e => setPositionEn(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
            <Col xs='12'>
              <div className="mb-3">
                <Label htmlFor="productdesc">Author Position(Arabic)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={positionAr}
                  onChange={e => setPositionAr(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col sm="12">
              <div>
                <Label htmlFor="productdesc">Author Description(English)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={descEn}
                  onChange={e => setDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="12">
              <div>
                <Label htmlFor="productdesc">Author Description(Arabic)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={descAr}
                  onChange={e => setDescAr(e.target.value)}
                />
              </div>
            </Col>
            {/* <Col sm="12" className="mt-3">
              <div>
                <Label htmlFor="productdesc">No of articles</Label>
                
                <Input
                  name="title"
                  type="number"
                  className="form-control input_cus"
                  value={descAr}
                  onChange={e => setDescAr(e.target.value)}
                />
              </div>
            </Col> */}
          </Row>
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={close}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AddAuthorForm
