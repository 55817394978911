import React, { useState, useEffect } from "react"
import { Row, Col, Form, Label, Input, Card } from "reactstrap"
import ReactLoading from "react-loading"
import axios from "axios"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"

const HeaderContent = () => {
  const [active, setActive] = useState(false)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [image, setImage] = useState(null)
  const [images, setImages] = useState([])
  const [prev, setPrev] = useState(null);
  const [isOpen, setisOpen] = useState(false)

  function handleImage(e, files, type) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    type == "vector"&&setImage(Object.values(files)[0])
    type == "images"&&setImages(images => images.concat(Object.values(files)))
    e.target.value = ""
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  function submit(e) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
      vector: image
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    images.forEach((img, i) => { 
      formData.append(`Images[${i}]`, img)
    })
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/resources/headerContent`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setActive(false)
        toast.success("Success Updated")
        getData()
      })
      .catch(err => {
        setActive(false)
        toast.error("Something went wrong")
      })
  }


  function deleteMultiImages (id) { 
    id&&axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/resources/headerContent/delete`,
        {id},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
  }

  function getData ()  {
    axios
    .get(`${process.env.REACT_APP_API}/dashboard/resources`, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      let data = res.data.data
      setTitleEn(data?.titleEn)
      setTitleAr(data?.titleAr)
      setDescEn(data?.descriptionEn)
      setDescAr(data?.descriptionAr)
      data?.vector&&setImage({preview: data?.vector})
      data?.Images&&setImages(imgs => data.Images.map( img => ({...img,preview: img.image})))
    })
    .catch(err => {
    })
  }


  useEffect(() => {
   getData()
  }, [])

  return (
    <div>
      {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Main Title (english)</Label>
              <Input
                id="itemnameen"
                name="titleEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Main Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="titleAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                name="describtionEn"
                className="form-control  mb-0"
                id="specification"
                rows="7"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                name="describtionAr"
                className="form-control  mb-0"
                id="specification"
                rows="7"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Vector</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleImage(e, e.target.files, "vector")}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {image ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={image.name}
                          src={image.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {image.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, image.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                          type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => setImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Images</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleImage(e, e.target.files, "images")}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {(images || []).map((image, i) => (
                <Card key={i} className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={image.name}
                          src={image.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {image.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, image.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                              style={{ color: "#ff3838" }}
                              onClick={e => (setImages(images => images.filter((img, k) => i !== k)), deleteMultiImages(image.id))}
                            >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) )}
            </div>
          </Col>
        </Row>
      </Form>
      <Row className="mt-2">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button onClick={submit}>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HeaderContent
