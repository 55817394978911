import { GET_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from "./actionTypes"

const INIT_STATE = {
  notifications: [],
  userCount: 0,
  toyCount: 0,
  notifyCount: 0,
  suggestionCount: 0,
  feedbackCount: 0,
  reviewCount: 0,
  reportCount: 0,
}

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...action.payload,
      }
    case UPDATE_NOTIFICATIONS:
      return {
        notifications: state.notifications,
        notifyCount:
          action.payload.unreadNotificationCount ||
          action.payload.unreadNotificationCount == 0
            ? action.payload.unreadNotificationCount
            : state.notifyCount,
        userCount:
          action.payload.unreadUserCount || action.payload.unreadUserCount == 0
            ? action.payload.unreadUserCount
            : state.userCount,
        toyCount:
          action.payload.unreadToyCount || action.payload.unreadToyCount == 0
            ? action.payload.unreadToyCount
            : state.toyCount,
        suggestionCount:
          action.payload.unreadSuggestionCount ||
          action.payload.unreadSuggestionCount == 0
            ? 0
            : state.suggestionCount,
        feedbackCount:
          action.payload.unreadFeedbackCount ||
          action.payload.unreadFeedbackCount == 0
            ? action.payload.unreadFeedbackCount
            : state.feedbackCount,
        reviewCount:
          action.payload.unreadReviewCount ||
          action.payload.unreadReviewCount == 0
            ? action.payload.unreadToyCount
            : state.reviewCount,
        reportCount:
          action.payload.unreadReportCount ||
          action.payload.unreadReportCount == 0
            ? action.payload.unreadReportCount
            : state.reportCount,
      }
    default:
      return state
  }
}

export default Notifications
