/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { useHistory } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useSelector, useDispatch } from "react-redux"
import { setTitle, getProjects } from "../../../store/actions"
import { toast } from "react-toastify"
import TreePicker from 'rsuite/TreePicker';
import 'rsuite/dist/rsuite.min.css';
import prettyBytes from "pretty-bytes";
import { getDateMeta } from "@fullcalendar/core"
import AdminPagination from "./Utilities/AdminPagination"
import axiosConfig from "axiosConfig"
import EditDesign from "./Components/Forms/EditDesign"
import { Toggle } from "rsuite"


const Post = props => {
  const queryParameters = new URLSearchParams(window.location.search)
  const pageNum = queryParameters.get("page")
  const history = useHistory()
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [avatar, setAvatar] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const [disabledNum, setDisabledNum] = useState([])
  const [categoryID, setCategoryID] =  useState('')
  const [category, setCategory] =  useState('')
  const [status, setStatus] =  useState('')
  const [categoriess,setCateogriess]=useState([])
  const categoryRef = useRef(null)
  const user = useSelector(state => state.UserData?.user)
  const sizePerPage = 10;
  const [page, setPage] = useState(1)
  const [totalSize, setTotalSize] = useState("10")
  const [filter, setFilter] = useState(null)

  
  useEffect(() => {
    setPage(pageNum?pageNum:1)
  }, [pageNum])
  
  const columns = [{
    dataField: 'id',
    text: 'Id',
    sort: true,
  },
  {
    dataField: "important",
    text: "Important",
    sort: true
  },  {
    dataField: 'titleEn',
    text: 'Title (English)',
    sort: true
  }, {
    dataField: 'DesignerName',
    text: 'Designer Name',
    sort: true
  }, {
    dataField: 'AllCategories',
    text: 'Categories',
    sort: true
  },
  , {
    dataField: 'AllImages',
    text: 'Images',
    sort: true
  },{
    dataField: 'actions',
    text: 'Actions',
    sort: true
  }];

  const pageOptions = {
    custom: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "75",
        value: 75,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: totalSize,
      },
    ],
  }

  

  const { SearchBar } = Search

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function clickHandle(id) {
    if (id) {
      return history.push(`/resources/socialPost/design/editDesign/${id}`)
    }
    history.push(`/resources/socialPost/design/addDesign`)
  }

  function deleteAvatar(id) {
    axiosConfig
      .post(
        `dashboard/socialPost/design/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(products => products.filter(prod => prod.id != id))
        toast.success("Design Deleted Successfuly !")
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  const ShowItem = (id,active) => {
    var resActive = 0;
    active == true ?  resActive = 1 : resActive = 0; 
    const formData = new FormData()
    let data = {
      active: resActive,
      id,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }

    axios.post( `${process.env.REACT_APP_API}/dashboard/socialPost/design/active`,formData,{
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    })
      .then(res => {
        GetData()
        toast.success(res.data.message)
      })
      .catch((error)=>{
      toast.error(error.data.message)
  })
 }



function GetData(page){
  axiosConfig
    .get(`dashboard/socialPost/design?page=${page}`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setTotalSize(res.data.data.pagination.total)
        setProductData(
          res.data.data.Designs.map(page => {
            console.log(page)
            return {
              ...page,
              important: <Toggle checked={page.important} key={page.id} onChange={(value) => {ShowItem(page.id,value)}}/>,

              
              DesignerName: page?.Designer?.nameEn,
            AllCategories:
                page?.Categories.length !== 0 ? (
                  page?.Categories.map((c, i) => (
                    <span
                      key={i}
                      className={`${c.nameEn === 1 ? "" : "image-opacity"}`}
                      style={{
                        backgroundColor: "rgb(216 216 216)",
                        color: "rgb(0, 0, 0)",
                        borderRadius: "45px",
                        padding: "5px 20px",
                        margin: "0px 2px",
                        display: "inline-block",
                      }}
                    >
                      {c.nameEn}
                    </span>
                  ))
                ) : (
                  <span className="fs-4">-</span>
                ),
                AllImages:
                page?.Images.length !== 0 ? (
                  page?.Images.map((c, i) => (
                    <img
                        key={i}
                        src={c.image}
                        onClick={e => setOpen(e.target.src)}
                        className="usernametabl"
                        alt=""
                    />
                    
                  ))
                ) : (
                  <span className="fs-4">-</span>
                ),


                titleEn: page.titleEn?page.titleEn:<span className="fs-4">-</span>,
               
              actions: (
                <div className="d-flex">
                  <a
                    style={{ ...editBtn, marginRight: "10px" }}
                    href={page.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="bx bx-link fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    View
                  </a>
                  <button style={editBtn} onClick={() => clickHandle(page.id)}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>
                  {user?.roleAccess[3]?.Delete&&<button
                    style={deleteBtn}
                    onClick={e => deleteAvatar(page.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>}
                </div>
              ),
            //   createdOn: page.created_at ? (
            //     page.created_at
            //   ) : (
            //     <span className="fs-4">-</span>
            //   ),
            }
          })
        )
      }).catch(err => {
        console.log(err)
      })
}

 
  useEffect(() => {
    dispatch(setTitle("Designs"))
    GetData(page)
  }, [page, filter])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Designs</title>
        </MetaTags>
        <div className="container-fluid p-0">
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            ></SweetAlert>
          ) : null}
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          
          <Card>
            <CardBody>
            
          <Row>
            <Col>
                  {page&&<AdminPagination 
                    data={productData} 
                    page={page}
                    setFilter={setFilter}
                    keyFiled='id'
                    sizePerPage={sizePerPage} 
                    totalSize={totalSize}
                    url=''
                    none={false}
                    onTableChange={(type, { page }) => {
                      setPage(page)
                    }} 
                    columns={columns}
                  />}
            </Col>
          </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Post

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
