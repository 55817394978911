import {
  GET_USER_DATA
} from "./actionTypes"

const INIT_STATE = {
  user: null,
}

const UserData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state
  }
}

export default UserData