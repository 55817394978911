import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import { Toast } from 'reactstrap';
const axiosConfig = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
        'Content-Type': 'application/json',
    }
});
axiosConfig.interceptors.response.use(response => {
    return response;
}, err => {
   
  
      
//     axios.post(`${process.env.REACT_APP_API}/dashboard/dveloperLogin`, {
//         'UID' : sessionStorage.getItem('UID'),
//         'UDeveloper' : sessionStorage.getItem('UDeveloper')
//     }, {
//         headers: { 
//           'Content-Type': 'application/json',
//         }
//     })
//    .then(response => {
//         localStorage.setItem("authUser", JSON.stringify(response.data.data))
//         createBrowserHistory().push('/dashboard');
//         window.location.reload();
//     })
//     .error(error =>{
        
        // localStorage.removeItem('authUser')
        // createBrowserHistory().push('/Login');
        // // window.location.reload();
        // toast.error('Account Opened From Other Device. Please Login Again')
    // })
//     if (error.response?.status === 401) {

       
        

        
//     // window.location.replace('/Login')
//    }
   return err;
 });
export default axiosConfig;