/* eslint-disable react/prop-types */
import React from "react"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import FooterContent from "../Components/FooterContent/FooterContent"

const FooterContentPage = () => {
  return (
    <React.Fragment>
      <div>
        <FooterContent />
      </div>
    </React.Fragment>
  )
}

export default FooterContentPage
