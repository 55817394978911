/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useState } from "react";

import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";


// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";


import logo from "../../assets/images/logo-light.svg";
import logoLightSvg from "../../assets/images/logo.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import ReactLoading from "react-loading";


const Header = props => {
  const title = useSelector(state => state.Title.title)
  const user = useSelector(state => state.UserData.user)


  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }


  return (
    <React.Fragment>
      <header id="page-topbar" style={{boxShadow: "none", borderBottom: "1px solid #eeecec"}}>
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block p-0 pr-2 pl-2">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light " style={{paddingRight: "5px", paddingLeft: "5px"}}>
                <span className="logo-md">
                  <img src={logoLightSvg} alt="" height="60" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
              style={{display: "flex", alignItems: "center", color: "#000"}}
            >
              <i className="fa fa-fw fa-bars fs-3" />
            </button>
            <h2 className='d-flex align-items-center m-0 p-0 fs-4 fw-bold pr-2 d-flex align-items-center'>{title}</h2>
          </div>


          <div className="d-flex align-items-center">
            {/* {user && user.role == 2? null: <button style={btnStyle} onClick={() => props.clickHandle()}>
              <i className='bx bx-plus-circle'></i>
              <span>Send Notification</span>
            </button>
            } */}
           
            {/* <LanguageDropdown />
             */}
            <NotificationDropdown />

            <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
            </div>
            <ProfileMenu />

          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));


let btnStyle = {
  background: "#3aaa35",
  width: "204px",
  height: "45px",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "none",
  fontSize: "16px",
  fontWeight: "600",
  gap: "5px",
  marginRight: "10px"
}