import React, { Fragment, useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
// import Select from 'react-select'
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap"
import Dropzone from "react-dropzone"

import { toast } from "react-toastify"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useHistory } from "react-router-dom"
import axios from "axios"
import PulseLoader from "react-spinners/PulseLoader"

import Select from "react-select"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import "./addproject.scss"
import {
  setTitle
} from "../../../../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css" // ES6
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import prettyBytes from "pretty-bytes"
import SeoComponent from "./SeoComponent"

const AddDesign = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const cats = useSelector(state => state.MainData.categories)
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [isOpen, setisOpen] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  //form data collect
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [link, setLink] = useState("")
  const [selectedFiles, setselectedFiles] = useState([])

  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [designers, setDesigners] = useState([])
  const[designer,setDesigner] = useState(null)
  const[designerErorr,setDesignerErorr] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [categories, setCategories] = useState([])








  

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size)
      })
    )
    setselectedFiles(fil => fil.concat(files))
  }

  function deleteSelectImage(e, id, type) {
    e.preventDefault()
    type === "cover" ? setCoverImages(files => files.filter((f, i) => i !== id)) : type === "thumb" ? setThumbImage(null) : type === "face" ? setFacebookImage(null) :
      setselectedFiles(files => files.filter((f, i) => i !== id))
  }

  function prevSelectImage(e, id) {
    e.preventDefault()
    setphotoIndex(id)
    setisOpen(!isOpen)
  }

  

  useEffect(() => {
    axios
      .get(
      `${process.env.REACT_APP_API}/dashboard/socialPost/profiles/designers`,
        {
          headers: {
            Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }
    )
    .then(res => {
      
      
      setDesigners(res.data.data)
          
    })
    .catch(err => {
    })
    
  }, [])
 

  function submitHandler() {
    let formData = new FormData()
    let data = {
      titleEn: titleEn,
      titleAr: titleAr,
      link: link,
      designer: designer
    }

    for (let key in data) {
      formData.append(key, data[key])
    }

    selectedCategories.forEach((category, i) => {
      formData.append(`Categories[${i}][category]`, category.id)
    })


    selectedFiles.forEach((photo,index) => {
      formData.append(`Images[${index}][image]`, photo)
    })


    setLoading(true)
    axiosConfig.post(`/dashboard/socialPost/design/add`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`
        }
      })
      .then(res => {
        setLoading(false)
        toast.success("Design Added Successfully", {position: "top-right"})
        history.push('/resources/socialPost/design')
    
      }).catch(err=>{
        setLoading(false)
        toast.error('Something went wrong')
      })
  }

  let getCategories = () => {
    axiosConfig
      .get(`/dashboard/socialPost/category`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setCategories(
          
            res.data.data.map(child => ({
              label: child.nameEn,
              id: child.id,
              status: child.status,
              value: child.id,
            })),
          
        )
      })
      .catch(err => {})
  }
  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    dispatch(setTitle("Add Design"))
  }, [cats])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Design</title>
        </MetaTags>
        <Container fluid>
          {isOpen ? (
            <Lightbox
              mainSrc={photoIndex}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
            {success_msg ? (
              <SweetAlert
                title={msg}
                success
                confirmBtnBsStyle="success"
                onConfirm={() => {
                  setsuccess_msg(false)
                }}
                onCancel={() => {
                  setsuccess_msg(false)
                }}
              >
              </SweetAlert>
            ) : null}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="p-0">
                  <h2
                    className="head__title fs-4 pl-4 pt-3 mb-2"
                    style={{
                      color: "#000",
                      fontSize: "24px",
                      fontWeight: "700 !important",
                      marginLeft: "20px"
                    }}
                  >
                    <i
                      className="dripicons-arrow-thin-left fs-5 fw-bold"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    />{" "}
                    Design 
                  </h2>

                  <Form className="p-4 add-project-form">
                    


                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Design Title (english)
                          </Label>
                          <Input
                            type="text"
                            className="form-control input_cus"
                            placeholder="e.g. Design Title"
                            value={titleEn}
                            onChange={e => setTitleEn(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Design Title (arabic)
                          </Label>
                          <Input
                            id="itemnamear"
                            name="itemnamear"
                            type="text"
                            className="form-control input_cus input_cus"
                            placeholder="مثال. عنوان التصميم"
                            value={titleAr}
                            onChange={e => setTitleAr(e.target.value)}
                            dir="rtl"
                            lang="ar"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col> 
                          <Label htmlFor="productdesc">Designer</Label>
                          <select className="form-control input_cus" value={designer} onChange={(e) => setDesigner(e.target.value)} >
                              <option disabled selected value=''>Choose Designer</option>
                              {designers.map((des) => <option key={des.id} value={des.id}>
                                
                              {des.nameEn}
                                
                              </option>)}
                            </select>
                            
                            {
                              designerErorr ? 
                              <span className="fs-6 text-danger">
                                { designerErorr }
                              </span> : ''
                          }
                      </Col>
                    </Row>
                    {categories ? (
                        <Row className="mb-3">
                          <Col>
                            <Label htmlFor="productdesc " >
                              Category
                            </Label>
                            <Select
                              isMulti={true}
                              name="categories"
                              value={selectedCategories}
                              closeMenuOnSelect={false}
                              onChange={e => setSelectedCategories(e)}
                              options={categories}
                              required
                              
                            />
                            <Input className="d-none"/>
                            
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                   

                    <Row className="mb-3">
                      <Col sm="12">
                        <div >
                          <Label htmlFor="productdesc">
                            Link
                          </Label>
                          <Input
                            id="brandnameen"
                            name="brandnameen"
                            type="text"
                            className="form-control input_cus"
                            placeholder="e.g. www.google.com"
                            value={link}
                            onChange={e => setLink(e.target.value)}
                          />
                        </div>
                      </Col>
                      
                    </Row>

                   
                  </Form>
                  
                  <h2
                    className="mb-3 mt-4 fs-4 pl-4"
                    style={{ marginLeft: "20px" }}
                  >
                    Design Images
                  </h2>
                  <div className="d-flex justify-content-between">
                    <Label
                      className="fs-5  pl-4 "
                      style={{ marginLeft: "20px" }}
                    >
                      Attached image
                    </Label>

                  </div>
                  <Form
                    className="pl-4 pr-4 pb-4"
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                    style={{ objectFit: "cover" }}
                                  />
                                </Col>
                                <Col>
                                  <Link to="#" className="text-muted ">
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col>
                                  <div className="image__options">
                                    <button
                                      className="p-0"
                                      onClick={e => prevSelectImage(e, f.preview)}
                                    >
                                      <i className="far fa-eye  fs-5 p-0"/>
                                    </button>
                                    <button
                                      style={{ color: "#ff3838" }}
                                      onClick={e => deleteSelectImage(e, i)}
                                    >
                                      <i className="bx bx-trash fs-4"/>
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                 
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="submit__btn footer fixed fixed-bottom">
            <Col lg={10} md={10}>
              <button className="cancel mr-3" onClick={() => history.goBack()}>
                cancel
              </button>
              {" "}
              <button className="save" onClick={submitHandler}>
                {loading ? (
                  <PulseLoader color={"#ffffff"} loading={true} size={10} />
                ) : (
                  "Save"
                )}
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddDesign

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0"
}


const modulesQuill = {
  toolbar: {
    container: [
      [{ "header": [1, 2, 3, 4, 5, 6, false] }],
      [{ "font": [] }],
      [{ "align": [] }],
      ["bold", "italic", "underline"],
      [{ "list": "ordered" }, { "list": "bullet" }, { "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "custom-color"] }, { "background": [] }, "link", "emoji"]
    ],
    handlers: {
      "color": function(value) {
        if (value === "custom-color") value = window.prompt("Enter Hex Color Code")
        this.quill.format("color", value)
      }
    }
  },
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      },
      handleEnter: {
        key: 13,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      }
    }
  }
}

const formatsQuill = [
  "header", "font", "size",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent", "align",
  "link", "image", "background", "color", "emoji"
]