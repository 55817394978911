/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link, useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "../specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const ChannelsForm = props => {
  let p = props
  const history = useHistory()
  const [isOpen, setisOpen] = useState(false)
  const [name_en, setNameEn] = useState("")
  const [nameEnError, setNameEnError] = useState(null)
  const [name_ar, setNameAr] = useState("")
  const [subscribers, setSubscribers] = useState(null)
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [prev, setPrev] = useState(null);
  const [slideImage, setSlideImage] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [slideImageError, setSlideImageError] = useState('');
  const [profileImageError, setProfileImageError] = useState('');
  
  const [subscribersError, setSubscribersError] = useState('');
  
  
  
  function handleSlideImageFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setSlideImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleProfileImageFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setProfileImage(Object.values(files)[0])
    e.target.value = ""
  }
  function deleteSlideImage(){
    
    setSlideImage(null)
  }
  function deleteProfileImage(){
    setProfileImage(null)
  }
  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }
  
  
  
  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      nameEn: name_en,
      nameAr: name_ar,
      subscribers: subscribers,
      id,
    }
    

    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append('slideImage', slideImage);
    formData.append('profileImage', profileImage)
    let reqLink = props.channel? `update`: `add`
    axios.post( `${process.env.REACT_APP_API}/dashboard/channels/${reqLink}`,formData,{
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    })
      .then(res => {
        props.getChannelData()
        toast.success(`Channel ${props.channel?'Updated':"Added"} Success`, {position: "top-right"})
        setLoading(false)
        props.setActive(false)
        props.setChannel(null)
      })
      .catch((error)=>{
        console.log(error.response.data.error);
          setLoading(false)
          if(error.response.data.error != null){
            
            
            if(error.response.data.error.nameEn != null){
              error.response.data.error.nameEn.map(error =>{
                setNameEnError(error)
              })
            }
            if(error.response.data.error.slideImage != null){
              error.response.data.error.slideImage.map(error =>{
                setSlideImageError(error)
              })
            }
            if(error.response.data.error.profileImage != null){
              error.response.data.error.profileImage.map(error =>{
                setProfileImageError(error)
              })
            }
            if(error.response.data.error.subscribers != null){
              error.response.data.error.subscribers.map(error =>{
                setSubscribersError(error)
              })
            }
          }
      })
    
  }

  useEffect(() => {
    if (p.channel) {
      setNameEn(p.channel.nameEn)
      setNameAr(p.channel.nameAr)
      setProfileImage(p.channel.profileImage)
      setSlideImage(p.channel.slideImage)
      setSubscribers(p.channel.subscribers)
      setId(p.channel.id)
      setNameEnError(null)
      return
    }
    
  }, [p.channel])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "550px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            Channel
          </div>
          <button onClick={() => (props.setActive(false), props.setChannel(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          
          {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
         
            

          <Row className="">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Channel Name (English)</Label>
                <Input
                  name="nameEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                  style={{ border: nameEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  nameEnError ? 
                  <span className="fs-6 text-danger">
                    { nameEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Channel Name (Arabic)</Label>
                <Input
                  name="nameAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
              <Col xs="12">
                <div>
                  <Label htmlFor="productdesc">Slide Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    name='attachmentImage'
                    onChange={e => handleSlideImageFiles(e, e.target.files)}
                    style={{ borderRadius: 0,border: slideImageError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                    // multiple
                  />
                   {
                    slideImageError ? 
                    <span className="fs-6 text-danger">
                    { slideImageError }
                    </span> : ''
                  }
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {slideImage ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={slideImage.name}
                              src={slideImage.preview ?slideImage.preview: slideImage }
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {slideImage.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(e, slideImage.preview)
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e => deleteSlideImage()}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>

              </Col>
          </Row>

          <Row className="mb-3">
              <Col xs="12">
                <div>
                  <Label htmlFor="productdesc">Profile Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    name='attachmentImage'
                    onChange={e => handleProfileImageFiles(e, e.target.files)}
                    style={{ borderRadius: 0,border: profileImageError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                    // multiple
                  />
                   {
                    profileImageError ? 
                    <span className="fs-6 text-danger">
                    { profileImageError }
                    </span> : ''
                  }
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {profileImage ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={profileImage.name}
                              src={profileImage.preview ?profileImage.preview: profileImage }
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {profileImage.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(e, profileImage.preview)
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e => deleteProfileImage()}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>

              </Col>
          </Row>
          

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">No. Subscribers</Label>
                <Input
                  name="subscribers"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.30"
                  value={subscribers}
                  onChange={e => setSubscribers(e.target.value)}
                />
                {
                  subscribersError ? 
                  <span className="fs-6 text-danger">
                    { subscribersError }
                  </span> : ''
                }
                
              </div>
            </Col>
          </Row>

         
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={() => (props.setActive(false), props.setChannel(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}

export default ChannelsForm
