/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link, useHistory } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "../specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const VideosForm = props => {
  let p = props
  const history = useHistory()
  const [isOpen, setisOpen] = useState(false)
  const [channel, setChannel] = useState(null)
  const [channels, setChannels] = useState([])
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [createdStatus, setCreatedStatus] = useState("")
  const [createdDate, setCreatedDate] = useState("")
  const [link, setLink] = useState("")
  const [createdStatusError, setCreatedStatusError] = useState(null)
  const [createdDateError, setCreatedDateError] = useState(null)
  const [linkError, setLinkError] = useState(null)
  const [channelError, setChannelError] = useState(null)
  const [titleEnError, setTitleEnError] = useState(null)
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [prev, setPrev] = useState(null);
  

  
  
  
  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      channel: channel,
      titleEn: titleEn,
      titleAr: titleAr,
      createdStatus:createdStatus,
      createdDate:createdDate,
      link:link,
      id,
    }

    for (let key in data) {
      formData.append(key, data[key])
    }
    let reqLink = props.vedio? `update`: `add`
    axios.post( `${process.env.REACT_APP_API}/dashboard/channels/videos/${reqLink}`,formData,{
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    })
      .then(res => {
        props.GetData(1)
        toast.success(`Video ${props.vedio?'Updated':"Added"} Success`, {position: "top-right"})
        setLoading(false)
        props.setActive(false)
        props.setVedio(null)
      })
      .catch((error)=>{
        console.log(error.response.data.error);
          setLoading(false)
          if(error.response.data.error != null){
            
            
            if(error.response.data.error.channel != null){
              error.response.data.error.channel.map(error =>{
                setChannelError(error)
              })
            }
            if(error.response.data.error.titleEn != null){
              error.response.data.error.titleEn.map(error =>{
                setTitleEnError(error)
              })
            }
           

            if(error.response.data.error.ctreatedDate != null){
              error.response.data.error.ctreatedDate.map(error =>{
                setCreatedDateError(error)
              })
            }
            if(error.response.data.error.ctreatedStatus != null){
              error.response.data.error.ctreatedStatus.map(error =>{
                setCreatedStatusError(error)
              })
            }
            if(error.response.data.error.link != null){
              error.response.data.error.link.map(error =>{
                setLinkError(error)
              })
            }
          }
      })
    
  }
  function getChannels() {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/channels`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setChannels(res.data.data)
      })
      .catch(err => {
        // err.response.data
      })
  
}

  useEffect(() => {
    getChannels()
    if (p.vedio) {
      setChannel(p.vedio.Channel.id)
      setTitleEn(p.vedio.titleEn)
      setTitleAr(p.vedio.titleAr)
      setCreatedStatus(p.vedio.createdStatus)
      setCreatedDate(p.vedio.createdDateForm)
      setLink(p.vedio.link)
      setId(p.vedio.id)
      setTitleEnError(null)

      return
    }
    
  }, [p.vedio])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "550px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
          Vedios
          </div>
          <button onClick={() => (props.setActive(false), props.setVedio(null))}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          
          {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
         
            <Row>
              <Col className="mb-3">
                <Label htmlFor="productdesc" >
                  Channel
                </Label>
                {channels ? <select
                  className="form-control input_cus"
                  value={channel}
                  onChange={(e) => setChannel(e.target.value)}
                  style={{ border: channelError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                >
                <option>Select</option>
                {
                  channels&&channels.map(cat=>(
                    <option value={cat.id} key={cat.id}>{cat.nameEn}</option>
                  ))
                }
                </select>:''}
                {
                  channelError ? 
                  <span className="fs-6 text-danger">
                    { channelError }
                  </span> : ''
                }
              </Col>
            </Row>

          <Row className="">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Title (English)</Label>
                <Input
                  name="tittleEn"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={titleEn}
                  onChange={e => setTitleEn(e.target.value)}
                  style={{ border: titleEnError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  titleEnError ? 
                  <span className="fs-6 text-danger">
                    { titleEnError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Title (Arabic)</Label>
                <Input
                  name="titleAr"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={titleAr}
                  onChange={e => setTitleAr(e.target.value)}
                />
                
              </div>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Label >Created Date</Label>
                <input
                  name="date"
                  type="date"
                  className="form-control input_cus"
                  value={createdDate}
                  style={{ border: createdDateError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  onChange={(e) => setCreatedDate(e.target.value)}
                />
                {
                  createdDateError ? 
                  <span className="fs-6 text-danger">
                    { createdDateError }
                  </span> : ''
                }
            </Col>
            <Col className="mb-3">
                <Label htmlFor="productdesc" >
                  Created Status
                </Label>
                <select
                  className="form-control input_cus"
                  value={createdStatus}
                  onChange={(e) => setCreatedStatus(e.target.value)}
                  style={{ border: createdStatusError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                >
                <option>Select</option>
                <option value="date" key="date">date</option>
                <option value="diff" key="diff">diff</option>
                  
                </select>
                {
                  createdStatusError ? 
                  <span className="fs-6 text-danger">
                    { createdStatusError }
                  </span> : ''
                }
              </Col>
              
          </Row>
          <Row className="">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Link</Label>
                <Input
                  name="link"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Link"
                  value={link}
                  onChange={e => setLink(e.target.value)}
                  style={{ border: linkError ?  '1px solid #CD4B51' : '1px solid #dadada' }}
                  
                />
                {
                  linkError ? 
                  <span className="fs-6 text-danger">
                    { linkError }
                  </span> : ''
                }
              </div>
            </Col>
          </Row>


         
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={() => (props.setActive(false), props.setVedio(null))}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
const imageStyle = {
  width: "60px",
  height: "60px",
  display: "block",
  objectFit: "contain",
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}

export default VideosForm
