import {
  GET_PROJECTS
} from "./actionTypes"

const INIT_STATE = {
  projects: [],
}

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      }
    default:
      return state
  }
}

export default projects
